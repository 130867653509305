import React from "react";
import "./Parallel.css";
import {
  AWS_REGION,
  AWS_IOT_ENDPOINT,
  AWS_CUSTOM_AUTH_USERNAME,
  AWS_CUSTOM_AUTH_AUTHORIZER_NAME,
  AWS_CUSTOM_AUTH_AUTHORIZER_PASSWORD,
  AWS_CUSTOM_AUTH_PASSWORD,
} from "../../views/MQTTSettings";
import { API_URL } from "variables/general";
import { useState } from "react";
import { Row, Col, Card, Container } from "reactstrap";
import { CSVLink } from "react-csv";
import { useEffect } from "react";
import { bmsIdContext } from "views/Dashboard";
import { useContext } from "react";
const iotsdk = require("aws-iot-device-sdk-v2");
const iot = iotsdk.iot;
const mqtt = iotsdk.mqtt;
let connection = null;
function Parallel(props) {
  useEffect(() => {
    main();
  }, []);
  const IotId = props.IotId
  const serverData = props.serverData
  const [mqttConnectionStatus, setMqttConnectionStatus] =
    useState("Not Connected");
  const [parallelMqttData, setParallelMqttData] = useState("");
  async function connect_websocket() {
    return new Promise((resolve, reject) => {
      let config = iot.AwsIotMqttConnectionConfigBuilder.new_default_builder()
        .with_clean_session(true)
        .with_client_id(new Date())
        .with_endpoint(AWS_IOT_ENDPOINT)
        .with_custom_authorizer(
          AWS_CUSTOM_AUTH_USERNAME,
          AWS_CUSTOM_AUTH_AUTHORIZER_NAME,
          AWS_CUSTOM_AUTH_PASSWORD,
          AWS_CUSTOM_AUTH_AUTHORIZER_PASSWORD
        )
        .with_keep_alive_seconds(30)
        .build();

      // console.log("Connecting custom authorizer...");
      setMqttConnectionStatus("Connecting...");
      const client = new mqtt.MqttClient();

      connection = client.new_connection(config);
      connection.on("connect", (session_present) => {
        setMqttConnectionStatus("Connected");
        console.log("Connected", session_present);
        resolve(connection);
      });
      connection.on("interrupt", (error) => {
        setMqttConnectionStatus("Connection Interrupted");
        // console.log(`Connection interrupted: error=${error}`);
      });
      connection.on("resume", (return_code, session_present) => {
        setMqttConnectionStatus("Connection Resumed");
        console.log(
          `Resumed: rc: ${return_code} existing session: ${session_present}`
        );
      });
      connection.on("disconnect", () => {
        setMqttConnectionStatus("Disconnected");
        // main();
        // console.log("Disconnected");
      });
      connection.on("error", (error) => {
        setMqttConnectionStatus("Error Occured");
        reject(error);
      });
      connection.connect();
    });
  }
  async function main(val) {
    connect_websocket()
      .then((connection) => {
        connection.subscribe(
          `adtiot/bms1.0/pub/bt/${IotId}`,
          mqtt.QoS.AtLeastOnce,
          (topic, payload, dup, qos, retain) => {
            const decoder = new TextDecoder("utf8");
            let message = decoder.decode(new Uint8Array(payload));
            // console.log("message", message);
            let parsed = JSON.parse(message);
            if (parsed.evc === "478") {
              console.log("parallel", parsed)
              setParallelMqttData(parsed);
            }
            else if (parsed.evc === "477") {
              // console.log("location", parsed)
              props.setLocationDataM(parsed);
            }
          }
        );

        // .then((publish) => {
        //     return connection.publish('publish.topic', "Hello World!", publish.qos);
        // });
      })
      .catch((reason) => {
        console.log(`Error while connecting: ${reason}`);
      });
  }
  const FaultArray = [
    "No Fault",
    "Over Pack Voltage Fault",
    "Over Pack Voltage",
    "Under Pack Voltage Fault",
    "Over OCD1 Current",
    "Over OCD2 Current",
    "Over Charge Current",
    "Over Discharge Current",
    "Short Circuit",
    "Cell Open Wire Fault",
    "Voltage Reference Fault",
    "Voltage Regulator Fault",
    "Temperature Multiplexer Error",
    "Under Temperature Fault",
    "Load Over Temperature",
    "Oscillator Fault",
    "Watchdog Timeout Fault",
    "Under Cell Voltage Fault",
    "Over Cell Voltage Fault",
    "Open Wire Fault",
    "Over Temperature Fault",
    "Cell Unbalance Pro",
    "SCD Latch High",
    "SPI Communication Fault",
    "I2C Communication Fault",
    "MCU Reset Fault",
    "Data CRC Error Fault",
    "Data Ready Fault",
    "Charger Authentication Failed",
    "Thermal Runaway Due To Temperature",
    "Thermal Runaway Due To Voltage",
    "MOSFET Drive Voltage Fault",
    "Reserve Condition Reached",
    "Battery Empty Reached",
    "Battery Full Charged Reached",
    "Weld Check Fault",			//35
    "Fuse Burn Fault",			//36
    "PCU Over Temp Fault",
    "Cell Temperature Imbalance Fault", 	//38
    "Precharge Complete Fault"
  ];
  if (serverData) {
    if (serverData.parallelValue.length > 0) {
      let batteryPack = [serverData.parallelValue[serverData.parallelValue.length - 1]]
      let bp_used = [serverData.parallelValue[serverData.parallelValue.length - 1].batteryPackNo]
      serverData.parallelValue.forEach(element => {
        if (bp_used.includes(element.batteryPackNo)) {
        }
        else {
          batteryPack.push(element)
          bp_used.push(element.batteryPackNo)
        }
      });
      let showArray = batteryPack
      if (showArray.length > 0) {
        if (parallelMqttData) {
          let parallelMqttDataArray = parallelMqttData.ext[0].parallelPackData.packDataArr;
          for (let i = 0; i < showArray.length; i++) {
            for (let j = 0; j < parallelMqttDataArray.length; j++) {
              if (showArray[i].batteryPackNo === parallelMqttDataArray[j].batteryPackNo
              ) {
                parallelMqttDataArray[j]["tms"] = parallelMqttData.tms
                parallelMqttDataArray[j]["mqttData"] = true
                showArray[i] = parallelMqttDataArray[j];
              }
            }
          }
        }
        return (
          <div>
            <Row>
              {showArray.map((val, i) => (
                <Col xs="12" sm="12" md="6" lg="6" xl="6">
                  <Card className={val.mqttData ? "parallel-card-green" : "parallel-card-red"}>
                    <Row>
                      <Col xs="12" sm="12" md="4" lg="4" xl="4">
                        <div class="battery-container">
                          <div class="battery-level" style={{ height: `${val.soc ? val.soc : 0}%` }}></div>
                          <div class="battery-capacity">
                            <div className="card-head" >SOC</div>
                            <span>{val.soc ? val.soc === 0 ? '0' : val.soc : val.soC === 0 ? '0' : val.soC} %</span>
                          </div>
                        </div>
                      </Col>
                      <Col xs="12" sm="12" md="8" lg="8" xl="8">
                        <Row>
                          <Col xs="12" sm="12" md="6" lg="6" xl="6">
                            <Card className="hero-cards">
                              <div className="card-head">Battery Pack Number</div>
                              <div className="card-foot">{val.batteryPackNo}</div>
                            </Card>
                          </Col>
                          <Col xs="12" sm="12" md="6" lg="6" xl="6">
                            <Card className="hero-cards">
                              <div className="card-head">Last Updated</div>
                              <div className="card-foot">
                                {val.tms.slice(11, 19)}<br />{val.tms.slice(8, 10)}-
                                {val.tms.slice(5, 7)}-{val.tms.slice(0, 4)}
                              </div>
                            </Card>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="12" sm="12" md="4" lg="4" xl="4">
                            <Card className="hero-cards">
                              <div className="card-head">Pack Current</div>
                              <div className="card-foot">{val.instantPackCurrent / 1000} A</div>
                            </Card>
                          </Col>
                          <Col xs="12" sm="12" md="4" lg="4" xl="4">
                            <Card className="hero-cards">
                              <div className="card-head">Pack Voltage</div>
                              <div className="card-foot">{val.packVoltage / 1000} V</div>
                            </Card>
                          </Col>
                          <Col xs="12" sm="12" md="4" lg="4" xl="4">
                            <Card className="hero-cards">
                              <div className="card-head">Current State</div>
                              <div className="card-foot">{val.packCurrentState}</div>
                            </Card>

                          </Col>
                        </Row>
                        <Row>
                          <Col xs="12" sm="12" md="4" lg="4" xl="4">
                            <Card className="hero-cards">
                              <div className="card-head">Pack Power</div>
                              <div className="card-foot">{val.instantPackPower} W</div>
                            </Card>
                          </Col>
                          <Col xs="12" sm="12" md="4" lg="4" xl="4">
                            <Card className="hero-cards">
                              <div className="card-head">Average Current</div>
                              <div className="card-foot">{val.avgPackCurrent / 1000} A</div>
                            </Card>
                          </Col>
                          <Col xs="12" sm="12" md="4" lg="4" xl="4">
                            <Card className="hero-cards">
                              <div className="card-head">Maximum Current</div>
                              <div className="card-foot">{val.maxPackCurrent / 1000} A</div>
                            </Card>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Card className="hero-cards">
                              <div className="card-head">Last Fault</div>
                              <div className="card-foot">{FaultArray[val.lastFault]}</div>
                            </Card>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="6" sm="6" md="4" lg="4" xl="4">
                        <Card className="hero-cards">
                          <div className="card-head">Full Charger Bit</div>
                          <div className="pin-div">
                            <div
                              className={
                                val.fullChargerBit
                                  ? "pin-state-green"
                                  : "pin-state-red"
                              }
                            ></div>
                          </div>
                        </Card>
                      </Col>
                      <Col xs="6" sm="6" md="4" lg="4" xl="4">
                        <Card className="hero-cards">
                          <div className="card-head">Ignition State</div>
                          <div className="pin-div">
                            <div
                              className={
                                val.ignitionState
                                  ? "pin-state-green"
                                  : "pin-state-red"
                              }
                            ></div>
                          </div>
                        </Card>
                      </Col>
                      <Col xs="6" sm="6" md="4" lg="4" xl="4">
                        <Card className="hero-cards">
                          <div className="card-head">Low Cell Bit</div>
                          <div className="pin-div">
                            <div
                              className={
                                val.lowCellBit ? "pin-state-green" : "pin-state-red"
                              }
                            ></div>
                          </div>
                        </Card>
                      </Col>
                      <Col xs="6" sm="6" md="4" lg="4" xl="4">
                        <Card className="hero-cards">
                          <div className="card-head">High Cell Bit</div>
                          <div className="pin-div">
                            <div
                              className={
                                val.highCellBit
                                  ? "pin-state-green"
                                  : "pin-state-red"
                              }
                            ></div>
                          </div>
                        </Card>
                      </Col>
                      <Col xs="6" sm="6" md="4" lg="4" xl="4">
                        <Card className="hero-cards">
                          <div className="card-head">High Pack Bit</div>
                          <div className="pin-div">
                            <div
                              className={
                                val.highPackBit
                                  ? "pin-state-green"
                                  : "pin-state-red"
                              }
                            ></div>
                          </div>
                        </Card>
                      </Col>
                      <Col xs="6" sm="6" md="4" lg="4" xl="4">
                        <Card className="hero-cards">
                          <div className="card-head">Parallel CAN Ignition Bit</div>
                          <div className="pin-div">
                            <div
                              className={
                                val.parallelCANIgnition
                                  ? "pin-state-green"
                                  : "pin-state-red"
                              }
                            ></div>
                          </div>
                        </Card>
                      </Col>
                      <Col xs="6" sm="6" md="4" lg="4" xl="4">
                        <Card className="hero-cards">
                          <div className="card-head">Charge Bit</div>
                          <div className="pin-div">
                            <div
                              className={
                                val.chargeBit ? "pin-state-green" : "pin-state-red"
                              }
                            ></div>
                          </div>
                        </Card>
                      </Col>
                      <Col xs="6" sm="6" md="4" lg="4" xl="4">
                        <Card className="hero-cards">
                          <div className="card-head">Discharge Bit</div>
                          <div className="pin-div">
                            <div
                              className={
                                val.dischargeBit
                                  ? "pin-state-green"
                                  : "pin-state-red"
                              }
                            ></div>
                          </div>
                        </Card>
                      </Col>
                      <Col xs="6" sm="6" md="4" lg="4" xl="4">
                        <Card className="hero-cards">
                          <div className="card-head">Charger Detect Bit</div>
                          <div className="pin-div">
                            <div
                              className={
                                val.chargerDetectBit
                                  ? "pin-state-green"
                                  : "pin-state-red"
                              }
                            ></div>
                          </div>
                        </Card>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              ))}
            </Row>
          </div>
        );
      }
      else return <div>No Data</div>
    }
    else return <div>No Data</div>
  } else return <div>loading...</div>
}

export default Parallel;
