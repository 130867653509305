import React, { useState, createContext } from "react";
import { useEffect } from "react";
import { Redirect } from "react-router";
import { Card, Row, Col, Button, UncontrolledTooltip } from "reactstrap";
import DataHeader from "./DataHeader";
import Tab from "./Tab";
import { API_URL } from "variables/general";
const decoder = new TextDecoder("utf-8");
let Ports;
let configurationArray = [];
let xModem128ByteChunk = [];
let packetNumber = [];
let incrementXModem = 0;
let waitForXmodem = false;
let BatChar_1;
let BatChar_2;
let BatChar_3;
let BatChar_4;
let BatterySerialInt = 0;
let batteryVoltage = 0;
let current = 0;
let AvrgCellTemp = 0;
let AvrgCellVolt = 0;
let SOC = 0;
let DisFET = 0;
let ChgFET = 0;
let BattLow = 0;
let NegativeContactor = 0
let PositiveContactor = 0
let BattHigh = 0;
let CellHigh = 0;
let IGN_S = 0;
let CellLow = 0;
let FullChargeBit = 0;
let AvailableCapacity = 0;
let CyclesCount = 0;
let SOH = 0;
let SOP = 0;
let LowSOC_Indication = 0;
let CAN_LoginStatusFlag = 0;
let IgnitionOverCANFlag = 0;
let BalancingStatus = 0;
let PDU_Temp = 0;
let MaxCellVolt = 0;
let MinCellVolt = 0;
let MaxCellTemp = 0;
let MinCellTemp = 0;
let AverageCellVolt = 0;
let NumberOfCell = 0;
let Cell1 = 0;
let Cell2 = 0;
let Cell3 = 0;
let cell4 = 0;
let Cell5 = 0;
let Cell6 = 0;
let Cell7 = 0;
let cell8 = 0;
let Cell9 = 0;
let Cell10 = 0;
let Cell11 = 0;
let cell12 = 0;
let Cell13 = 0;
let Cell14 = 0;
let Cell15 = 0;
let cell16 = 0;
let Cell17 = 0;
let Cell18 = 0;
let Cell19 = 0;
let cell20 = 0;
let Cell21 = 0;
let Cell22 = 0;
let Cell23 = 0;
let Cell24 = 0;
let CellMaxMinDiff = 0;
let NumOfTemp = 0;
let Temp1 = 0;
let Temp2 = 0;
let Temp3 = 0;
let Temp4 = 0;
let Temp5 = 0;
let Temp6 = 0;
let Temp7 = 0;
let Temp8 = 0;
let IC1Temp = 0;
let IC2Temp = 0;
let PDUTemp = 0;
let PCBTemp2 = 0;
let HeatSink1Temp = 0;
let HeatSink2Temp = 0;
let bmsPowerMode;
let packCurrentState = 0;
let Alarm = 0;
let Fault = 0;
let averageTimeToEmpty = 0;
let averageTimeToFull = 0;
let Char1;
let Char2;
let Char3;
let Char4;
let Char5;
let Char6;
let Char7;
let Char8;
let DisChargeMOSFETRx = 0;
let ChargeMOSFETRx = 0;
let IgnitionOverCANFlagrx = 0;
let WarningFlags = 0;
let EnergyOut = 0;
let EnergyIn = 0;
let Year = 0;
let month = 0;
let day = 0;
let hour = 0;
let minutes = 0;
let seconds = 0;
let BMSModelID_Char1;
let BMSModelID_Char2;
let BMSModelID_Char3;
let BMSModelID_Char4;
let BMSModelID_Char5;
let BMSModelID_Char6;
let BMSModelID_Char7;
let BMSConfigVersion_CRC16bit;
let PDUHardwareVersion_Char1;
let PDUHardwareVersion_Char2;
let PDUHardwareVersion_Char3;
let PDUHardwareVersion_Char4;
let PDUHardwareVersion_Char5;
let PDUHardwareVersion_Char6;
let PDUHardwareVersion_Char7;
let PDUHardwareVersion_Char8;
let BMSHardwareVersion_Char1;
let BMSHardwareVersion_Char2;
let BMSHardwareVersion_Char3;
let BMSHardwareVersion_Char4;
let BMSHardwareVersion_Char5;
let BMSHardwareVersion_Char6;
let BMSHardwareVersion_Char7;
let BMSHardwareVersion_Char8;
let BMSFirmwareVersion_Char1;
let BMSFirmwareVersion_Char2;
let BMSFirmwareVersion_Char3;
let BMSFirmwareVersion_Char4;
let BMSFirmwareVersion_Char5;
let BMSFirmwareVersion_Char6;
let BMSFirmwareVersion_Char7;
let BMSFirmwareVersion_Char8;
let BMS_CommandChar1;
let BMS_CommandChar2;
let BMS_CommandChar3;
let BMS_Command1;
let BMS_Command2;
let BMS_Command3;
let BMS_Command4;
let BMS_Command5;
let DEV_ON_OFF;
let ISL_GLOBAL_FAULT;
let ISL_GLOBAL_EN;
let BMS_INGITION;
let D_MOSFETs;
let C_MOSFETs;
let P_MOSFETs;
let SCD_ALERT;
let V5_ON_OFF;
let STATUS_PIN;
let POWER_GOOD_PIN;
let SetYear;
let SetMonth;
let SetDay;
let SetHour;
let SetMinute;
let SetSecond;
let cmd1;
let cmd2;
let stopCommandCenter = false;
let batterySerialArray = "";
let bmsModelId = "";
let bmsHardwareVersion = "";
let PDUHardwareVersion = "";
let FirmwareVersion = "";
let readerRef;
let keepReading = true;

// for 36S bms-----------------> cell voltages
let packetIndex = 0;
let CellTempPacketIndex = 0
let ExtrTempPacketIndex = 0
let cellVoltageFor24SPlus = [];
let BMS_NoOfCellAvailable = 0;
let BMS_NoOfTempAvailable = 0;
let cellTempFor24SPlus = [];
let BMS_NoOfExterTempAvailable = 0;
let extrTempFor24SPlus = [];
let showUiFor24SPlus = false
let batteryPackId1 = ''
let batteryPackId2 = ''
function CANHome() {
  const [deviceValue, setDeviceValue] = useState();
  const [CANBaudRate, setCANBaudRate] = useState();
  const [navigateBack, setNavigateBack] = useState(false);
  const [fotaSuccessful, setFotaSuccessful] = useState(false);
  const [getDataDisabled, setGetDataDisabled] = useState(true);
  const [getDataClicked, setGetDataClicked] = useState(false);
  const [fetchConfigDisabled, setFetchConfigDisabled] = useState(true);
  const [sendConfigDisabled, setSendConfigDisabled] = useState(true);
  const [resetBmsDisabled, setResetBmsDisabled] = useState(true);
  const [startUpdatingDisabled, setStartUpdatingDisabled] = useState(true);
  const [inFotaMode, setInFotaMode] = useState(false);
  const [fotaProcess, setFotaProcess] = useState("");
  const [fileUploaded, setFileUploaded] = useState(false);
  const [connectionEstablished, setConnectionEstablished] = useState(false);
  const [apiFileUploadSuccess, setApiFileUploadSuccess] = useState(false);
  const [apiFotaFileInput, setApiFotaFileInput] = useState("");
  const [apiFotaFileSize, setApiFotaFileSize] = useState("");
  const [ChargerBaudrate, setChargerBaudRate] = useState('')
  const [showLoaderInApiDownload, setShowLoaderInApiDownload] = useState(false)
  const [chargerDataRecieved, setChargerDataRecieved] = useState('')
  const [chargerStateDataRecieved, setChargerStateDataRecieved] = useState('')
  const [chargerDetailsDataRecieved, setChargerDetailsDataRecieved] = useState('')
  const [chargerACDCParameter1DataRecieved, setChargerACDCParameter1DataRecieved] = useState('')

  const apiFOTAFile = () => {
    setShowLoaderInApiDownload(true)
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        fileName: apiFotaFileInput,
      }),
    };
    fetch(API_URL + "/bms/fota_file", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log('result',result)npm start
        let sm = result.data.data;
        let array = sm.split(" ");
        setApiFotaFileSize(array.length)
        // console.log(array.length);
        if (result.status === true) {
          setShowLoaderInApiDownload(false)
          setApiFileUploadSuccess(true);
          apiFOTAFileInput(array);
        }
      })
      .catch((error) => {
        setShowLoaderInApiDownload(false)
        console.log("error", error)
        alert('File Error')
      }
      );
  };
  async function fetchConfiguration() {
    CANLoginCommandSend();
    let dataArray = [0x41, 0x44, 0x54, 0x26, 0x6d, 0x00, 0x00, 0x00];
    let canId = integerTo4ByteArray(512496474);
    CommandCenter(canId, 1, dataArray);
  }
  async function ViewDeviceData() {
    array = Array.from(deviceValue);
    let canId = [array[7], array[6], array[5], array[4]];
    let canValue = byteArrayToInt4(canId);
    if (canValue === 412496471) {
      // console.log("HeartBeat");
      batteryVoltage = byteArrayTounsignedInt2([array[9], array[10]]) / 100;
      current = byteArrayToInt2([array[11], array[12]]) / 100;
      AvrgCellTemp = byteArrayToInt1(array[13]);
      AvrgCellVolt = byteArrayToInt1(array[14]) / 10;
      SOC = byteArrayToInt1(array[15]);
      DisFET = bitShift(array[16], 7);
      ChgFET = bitShift(array[16], 6);
      BattLow = bitShift(array[16], 5);
      BattHigh = bitShift(array[16], 4);
      CellHigh = bitShift(array[16], 3);
      IGN_S = bitShift(array[16], 2);
      CellLow = bitShift(array[16], 1);
      FullChargeBit = bitShift(array[16], 0);
      if (configurationArray.length === 0) fetchConfiguration();
    } else if (canValue === 512496498) {
      batteryVoltage = byteArrayTounsignedInt2([array[9], array[10]]) / 10;
      current = byteArrayToInt2([array[11], array[12]]) / 10;
      AvrgCellTemp = byteArrayToInt1(array[13]);
      AvrgCellVolt = byteArrayToUnsignedInt1(array[14]);
      SOC = byteArrayToUnsignedInt1(array[15]);
      NegativeContactor = bitShift(array[16], 0)
      PositiveContactor = bitShift(array[16], 1)
      BattLow = bitShift(array[16], 2);
      BattHigh = bitShift(array[16], 3);
      CellHigh = bitShift(array[16], 4);
      IGN_S = bitShift(array[16], 5);
      CellLow = bitShift(array[16], 6);
      FullChargeBit = bitShift(array[16], 7);
      if (configurationArray.length === 0) fetchConfiguration();
    } else if (canValue === 412496472) {
      // console.log("PackInfo");
      AvailableCapacity = byteArrayToInt2([array[9], array[10]]);
      CyclesCount = byteArrayToInt2([array[11], array[12]]);
      SOH = byteArrayToInt1(array[13]);
      SOP = byteArrayToInt1(array[14]);
      BalancingStatus = bitShift(array[15], 7);
      LowSOC_Indication = bitShift(array[15], 6);
      CAN_LoginStatusFlag = bitShift(array[15], 5);
      IgnitionOverCANFlag = bitShift(array[15], 4);
      PDU_Temp = array[16];
    } else if (canValue === 412496473) {
      // console.log("CellTempInfo");
      MaxCellVolt = byteArrayToInt2([array[9], array[10]]);
      MinCellVolt = byteArrayToInt2([array[11], array[12]]);
      MaxCellTemp = byteArrayToInt1(array[13]);
      MinCellTemp = byteArrayToInt1(array[14]);
      AverageCellVolt = byteArrayToInt2([array[15], array[16]]);
    } else if (canValue === 412496474) {
      // console.log("CellVoltages0");
      NumberOfCell = array[9];
      Cell1 = byteArrayToInt2([array[10], array[11]]);
      Cell2 = byteArrayToInt2([array[12], array[13]]);
      Cell3 = byteArrayToInt2([array[14], array[15]]);
    } else if (canValue === 412496475) {
      // console.log("CellVoltages1");
      cell4 = byteArrayToInt2([array[9], array[10]]);
      Cell5 = byteArrayToInt2([array[11], array[12]]);
      Cell6 = byteArrayToInt2([array[13], array[14]]);
      Cell7 = byteArrayToInt2([array[15], array[16]]);
    } else if (canValue === 412496476) {
      // console.log("CellVoltages2");
      cell8 = byteArrayToInt2([array[9], array[10]]);
      Cell9 = byteArrayToInt2([array[11], array[12]]);
      Cell10 = byteArrayToInt2([array[13], array[14]]);
      Cell11 = byteArrayToInt2([array[15], array[16]]);
    } else if (canValue === 412496477) {
      // console.log("CellVoltages3");
      cell12 = byteArrayToInt2([array[9], array[10]]);
      Cell13 = byteArrayToInt2([array[11], array[12]]);
      Cell14 = byteArrayToInt2([array[13], array[14]]);
      Cell15 = byteArrayToInt2([array[15], array[16]]);
    } else if (canValue === 412496478) {
      // console.log("CellVoltages4");
      cell16 = byteArrayToInt2([array[9], array[10]]);
      Cell17 = byteArrayToInt2([array[11], array[12]]);
      Cell18 = byteArrayToInt2([array[13], array[14]]);
      Cell19 = byteArrayToInt2([array[15], array[16]]);
    } else if (canValue === 412496479) {
      // console.log("CellVoltages5");
      cell20 = byteArrayToInt2([array[9], array[10]]);
      Cell21 = byteArrayToInt2([array[11], array[12]]);
      Cell22 = byteArrayToInt2([array[13], array[14]]);
      Cell23 = byteArrayToInt2([array[15], array[16]]);
    } else if (canValue === 412496480) {
      // console.log("CellVoltages6nStatus");
      Cell24 = byteArrayToInt2([array[9], array[10]]);
      CellMaxMinDiff = byteArrayToInt2([array[11], array[12]]);
    } else if (canValue === 412496481) {
      // console.log("TempValues0");
      NumOfTemp = array[9];
      Temp1 = byteArrayToInt1(array[10]);
      Temp2 = byteArrayToInt1(array[11]);
      Temp3 = byteArrayToInt1(array[12]);
      Temp4 = byteArrayToInt1(array[13]);
      Temp5 = byteArrayToInt1(array[14]);
      Temp6 = byteArrayToInt1(array[15]);
      Temp7 = byteArrayToInt1(array[16]);
    } else if (canValue === 412496482) {
      // console.log("TempValues1");
      Temp8 = byteArrayToInt1(array[9]);
      IC1Temp = byteArrayToInt1(array[10]);
      IC2Temp = byteArrayToInt1(array[11]);
      PDUTemp = byteArrayToInt1(array[12]);
      PCBTemp2 = byteArrayToInt1(array[13]);
      HeatSink1Temp = byteArrayToInt1(array[14]);
      HeatSink2Temp = byteArrayToInt1(array[15]);
    } else if (canValue === 412496483) {
      // console.log("BMSStates");
      bmsPowerMode = array[9];
      packCurrentState = array[10];
      Alarm = array[11];
      Fault = array[12];
      averageTimeToEmpty = byteArrayToInt2([array[13], array[14]]);
      averageTimeToFull = byteArrayToInt2([array[15], array[16]]);
    } else if (canValue === 412496470) {
      // console.log("BatteryPackSerialNo");
      BatChar_1 = array[9];
      BatChar_2 = array[10];
      BatChar_3 = array[11];
      BatChar_4 = array[12];
      BatterySerialInt = byteArrayToInt4([
        array[13],
        array[14],
        array[15],
        array[16],
      ]);
      batterySerialArray = decoder.decode(
        new Uint8Array([
          BatChar_1,
          BatChar_2,
          BatChar_3,
          BatChar_4,
          BatterySerialInt,
        ])
      );
    } else if (canValue === 512496471) {
      // console.log("CANLogInRx");
      Char1 = array[9];
      Char2 = array[10];
      Char3 = array[11];
      Char4 = array[12];
      Char5 = array[13];
      Char6 = array[14];
      Char7 = array[15];
      Char8 = array[16];
    } else if (canValue === 512496473) {
      // console.log("CAN_MOSFETsConfigRx");
      DisChargeMOSFETRx = array[9];
      ChargeMOSFETRx = array[10];
      IgnitionOverCANFlagrx = array[11];
    } else if (canValue === 412496484) {
      // console.log("BMS_WarningFlags");
      WarningFlags = array[9];
    } else if (canValue === 412496485) {
      // console.log("EnergyDetails");
      EnergyOut = byteArrayToInt4([array[9], array[10], array[11], array[12]]);
      EnergyIn = byteArrayToInt4([array[13], array[14], array[15], array[16]]);
    } else if (canValue === 419358208) {
      // console.log("CAN_TimeStamp");
      Year = array[9];
      month = array[10];
      day = array[11];
      hour = array[12];
      minutes = array[13];
      seconds = array[14];
    } else if (canValue === 412496491) {
      // console.log("CAN_BMSModelID");
      BMSModelID_Char1 = array[9];
      BMSModelID_Char2 = array[10];
      BMSModelID_Char3 = array[11];
      BMSModelID_Char4 = array[12];
      BMSModelID_Char5 = array[13];
      BMSModelID_Char6 = array[14];
      BMSModelID_Char7 = array[15];
      bmsModelId = decoder.decode(
        new Uint8Array([
          BMSModelID_Char1,
          BMSModelID_Char2,
          BMSModelID_Char3,
          BMSModelID_Char4,
          BMSModelID_Char5,
          BMSModelID_Char6,
          BMSModelID_Char7,
        ])
      );
    } else if (canValue === 412496490) {
      // console.log("CAN_BMSConfigVersion");
      BMSConfigVersion_CRC16bit = array[9];
    } else if (canValue === 412496489) {
      // console.log("CAN_PDUHardwareVersion");
      PDUHardwareVersion_Char1 = array[9];
      PDUHardwareVersion_Char2 = array[10];
      PDUHardwareVersion_Char3 = array[11];
      PDUHardwareVersion_Char4 = array[12];
      PDUHardwareVersion_Char5 = array[13];
      PDUHardwareVersion_Char6 = array[14];
      PDUHardwareVersion_Char7 = array[15];
      PDUHardwareVersion_Char8 = array[16];
      PDUHardwareVersion = decoder.decode(
        new Uint8Array([
          PDUHardwareVersion_Char1,
          PDUHardwareVersion_Char2,
          PDUHardwareVersion_Char3,
          PDUHardwareVersion_Char4,
          PDUHardwareVersion_Char5,
          PDUHardwareVersion_Char6,
          PDUHardwareVersion_Char7,
          PDUHardwareVersion_Char8,
        ])
      );
    } else if (canValue === 412496488) {
      // console.log("CAN_BMSHardwareVersion");
      BMSHardwareVersion_Char1 = array[9];
      BMSHardwareVersion_Char2 = array[10];
      BMSHardwareVersion_Char3 = array[11];
      BMSHardwareVersion_Char4 = array[12];
      BMSHardwareVersion_Char5 = array[13];
      BMSHardwareVersion_Char6 = array[14];
      BMSHardwareVersion_Char7 = array[15];
      BMSHardwareVersion_Char8 = array[16];
      bmsHardwareVersion = decoder.decode(
        new Uint8Array([
          BMSHardwareVersion_Char1,
          BMSHardwareVersion_Char2,
          BMSHardwareVersion_Char3,
          BMSHardwareVersion_Char4,
          BMSHardwareVersion_Char5,
          BMSHardwareVersion_Char6,
          BMSHardwareVersion_Char7,
          BMSHardwareVersion_Char8,
        ])
      );
    } else if (canValue === 412496487) {
      // console.log("CAN_BMSFirmwareVersion");
      BMSFirmwareVersion_Char1 = array[9];
      BMSFirmwareVersion_Char2 = array[10];
      BMSFirmwareVersion_Char3 = array[11];
      BMSFirmwareVersion_Char4 = array[12];
      BMSFirmwareVersion_Char5 = array[13];
      BMSFirmwareVersion_Char6 = array[14];
      BMSFirmwareVersion_Char7 = array[15];
      BMSFirmwareVersion_Char8 = array[16];
      FirmwareVersion = decoder.decode(
        new Uint8Array([
          BMSFirmwareVersion_Char1,
          BMSFirmwareVersion_Char2,
          BMSFirmwareVersion_Char3,
          BMSFirmwareVersion_Char4,
          BMSFirmwareVersion_Char5,
          BMSFirmwareVersion_Char6,
          BMSFirmwareVersion_Char7,
          BMSFirmwareVersion_Char8,
        ])
      );
    } else if (canValue === 512496474) {
      // console.log("BMS_Command_OverCAN");
      BMS_CommandChar1 = array[9];
      BMS_CommandChar2 = array[10];
      BMS_CommandChar3 = array[11];
      BMS_Command1 = array[12];
      BMS_Command2 = array[13];
      BMS_Command3 = array[14];
      BMS_Command4 = array[15];
      BMS_Command5 = array[16];
    } else if (canValue === 412496492) {
      // console.log("CAN_Flags_PinStates");
      DEV_ON_OFF = bitShift(array[9], 0);
      ISL_GLOBAL_FAULT = bitShift(array[9], 1);
      ISL_GLOBAL_EN = bitShift(array[9], 2);
      BMS_INGITION = bitShift(array[9], 3);
      D_MOSFETs = bitShift(array[9], 4);
      C_MOSFETs = bitShift(array[9], 5);
      P_MOSFETs = bitShift(array[9], 6);
      SCD_ALERT = bitShift(array[9], 7);
      V5_ON_OFF = bitShift(array[10], 0);
      STATUS_PIN = bitShift(array[10], 1);
      POWER_GOOD_PIN = bitShift(array[10], 2);
    } else if (canValue === 512496492) {
      // console.log("BMS_TimeSet");
      SetYear = array[9];
      SetMonth = array[10];
      SetDay = array[11];
      SetHour = array[12];
      SetMinute = array[13];
      SetSecond = array[14];
    } else if (canValue === 512496494) {
      // console.log("Bootloader Rx");
      cmd1 = array[9];
      cmd2 = array[10];
      CreateDataPackets(cmd1, cmd2);
    } else if (canValue === 412496486) {
      // console.log("configuration");
      configurationArray.push(
        deviceValue[10],
        deviceValue[11],
        deviceValue[12],
        deviceValue[13],
        deviceValue[14],
        deviceValue[15],
        deviceValue[16]
      );
      if (configurationArray.length === 161) setFetchConfigDisabled(false);
    } else if (canValue === 412496498) {
      BMS_NoOfCellAvailable = array[9]
      BMS_NoOfTempAvailable = array[10]
      BMS_NoOfExterTempAvailable = array[11]
      showUiFor24SPlus = true
    } else if (canValue === 412496497) {
      packetIndex = array[9]
      cellVoltageFor24SPlus[packetIndex] = (getBitValueFor24PlusS(array[11], 0, 4) << 8) | array[10];
      cellVoltageFor24SPlus[packetIndex + 1] = getBitValueFor24PlusS(array[11], 5, 7) | array[12] << 3 | getBitValueFor24PlusS(array[13], 0, 1) << 11;
      cellVoltageFor24SPlus[packetIndex + 2] = getBitValueFor24PlusS(array[13], 2, 7) | array[14] << 6;
      cellVoltageFor24SPlus[packetIndex + 3] = getBitValueFor24PlusS(array[16], 0, 4) << 8 | array[15];
    } else if (canValue === 412496493) {
      CellTempPacketIndex = array[9]
      cellTempFor24SPlus[CellTempPacketIndex] = byteArrayToInt2([array[11], array[12]]) / 10
      cellTempFor24SPlus[CellTempPacketIndex + 1] = byteArrayToInt2([array[13], array[14]]) / 10
      cellTempFor24SPlus[CellTempPacketIndex + 2] = byteArrayToInt2([array[15], array[16]]) / 10
    } else if (canValue === 412496494) {
      ExtrTempPacketIndex = array[9]
      extrTempFor24SPlus[ExtrTempPacketIndex] = byteArrayToInt2([array[11], array[12]]) / 10
      extrTempFor24SPlus[ExtrTempPacketIndex + 1] = byteArrayToInt2([array[13], array[14]]) / 10
      extrTempFor24SPlus[ExtrTempPacketIndex + 2] = byteArrayToInt2([array[15], array[16]]) / 10
    } else if (canValue === 512496499) {
      batteryPackIdRecieved(array)

    } else if (canValue === 285021482) {
      setChargerDataRecieved(array)
    } else if (canValue === 285021484) {
      setChargerStateDataRecieved(array)
    } else if (canValue === 285021485) {
      setChargerDetailsDataRecieved(array)
    } else if (canValue === 285021483) {
      setChargerACDCParameter1DataRecieved(array)
    }
  }

  let dataArray = [
    day + "/" + month + "/" + Year,
    hour + ":" + minutes + ":" + seconds,
    batteryVoltage,
    current,
    AvrgCellTemp,
    AvrgCellVolt,
    SOC,
    DisFET,
    ChgFET,
    BattLow,
    BattHigh,
    CellHigh,
    IGN_S,
    CellLow,
    FullChargeBit,
    AvailableCapacity,
    CyclesCount,
    SOH,
    SOP,
    LowSOC_Indication,
    CAN_LoginStatusFlag,
    IgnitionOverCANFlag,
    BalancingStatus,
    PDU_Temp,
    MaxCellVolt,
    MinCellVolt,
    MaxCellTemp,
    MinCellTemp,
    AverageCellVolt,
    NumberOfCell,
    Cell1,
    Cell2,
    Cell3,
    cell4,
    Cell5,
    Cell6,
    Cell7,
    cell8,
    Cell9,
    Cell10,
    Cell11,
    cell12,
    Cell13,
    Cell14,
    Cell15,
    cell16,
    Cell17,
    Cell18,
    Cell19,
    cell20,
    Cell21,
    Cell22,
    Cell23,
    Cell24,
    CellMaxMinDiff,
    NumOfTemp,
    Temp1,
    Temp2,
    Temp3,
    Temp4,
    Temp5,
    Temp6,
    Temp7,
    Temp8,
    IC1Temp,
    IC2Temp,
    PDUTemp,
    PCBTemp2,
    HeatSink1Temp,
    HeatSink2Temp,
    bmsPowerMode,
    packCurrentState,
    Alarm,
    Fault,
    averageTimeToEmpty,
    averageTimeToFull,
    DisChargeMOSFETRx,
    ChargeMOSFETRx,
    IgnitionOverCANFlagrx,
    WarningFlags,
    EnergyOut,
    EnergyIn,
    BMSConfigVersion_CRC16bit,
    DEV_ON_OFF,
    ISL_GLOBAL_FAULT,
    ISL_GLOBAL_EN,
    BMS_INGITION,
    D_MOSFETs,
    C_MOSFETs,
    P_MOSFETs,
    SCD_ALERT,
    V5_ON_OFF,
    STATUS_PIN,
    POWER_GOOD_PIN,
    bmsHardwareVersion,
    batterySerialArray,
    bmsModelId,
    PDUHardwareVersion,
    FirmwareVersion,
    "\n",
  ];
  useEffect(() => {
    if (deviceValue) ViewDeviceData();
  }, [deviceValue]);
  let array;
  navigator.serial.addEventListener("connect", (e) => {
    // Connect to `e.target` or add it to a list of available ports.
  });

  navigator.serial.addEventListener("disconnect", (e) => {
    // Remove `e.target` from the list of available ports.
  });

  navigator.serial.getPorts().then((ports) => {
    // console.log(ports);
    // Initialize the list of available ports with `ports` on page load.
  });
  const RequestDevice = () => {
    const filters = [{ usbVendorId: 0x0403, usbProductId: 0x6001 }];
    navigator.serial
      .requestPort()
      .then((port) => {
        port.open({ baudRate: 256000 });
        Ports = port;
        setConnectionEstablished(true);
        // console.log("Device connected");
        setResetBmsDisabled(false);
        setSendConfigDisabled(false);
        setGetDataDisabled(false);

        keepReading = true;
        // Connect to `port` or add it to the list of available ports.
      })
      .catch((e) => {
        console.log(e);
        // The user didn't select a port.
      });
  };
  function Send(value) {
    const writer = Ports.writable.getWriter();
    console.log("send data ------------------", value);
    writer.write(new Uint8Array(value).buffer);
    writer.releaseLock();
  }
  function batteryPackIdRecieved(data) {
    switch (data[9]) {
      case 0:
        batteryPackId1 = String.fromCharCode(data[10]) + String.fromCharCode(data[11]) + String.fromCharCode(data[12]) + String.fromCharCode(data[13]) + String.fromCharCode(data[14]) + String.fromCharCode(data[15]) + String.fromCharCode(data[16])
        break;
      case 1:
        batteryPackId2 = String.fromCharCode(data[10]) + String.fromCharCode(data[11]) + String.fromCharCode(data[12]) + String.fromCharCode(data[13]) + String.fromCharCode(data[14]) + String.fromCharCode(data[15]) + String.fromCharCode(data[16])
        break;

      default:
        break;
    }
    console.log("batteryPackId1:", batteryPackId1)
    console.log("batteryPackId2:", batteryPackId2)
  }
  async function Read() {
    setGetDataDisabled(true);
    setStartUpdatingDisabled(false);
    setGetDataClicked(true);
    while (Ports.readable && keepReading) {
      readerRef = Ports.readable.getReader();
      try {
        let bytes = new Uint8Array();
        while (true) {
          // read the next chunk of bytes from the stream
          const { value, done } = await readerRef.read();
          console.log("Read-value----------", value);
          // console.log("length----------", value.length  )
          // append the new bytes to the existing bytes
          const newBytes = new Uint8Array(bytes.length + value.length);
          newBytes.set(bytes);
          newBytes.set(value, bytes.length);
          bytes = newBytes;

          // if we've read at least 21 bytes, call your method with the processed bytes
          while (bytes.length >= 21) {
            console.log("bytesss----------", bytes.length  )
            // console.log("DATA CALLLLLL----------", bytes.slice(0, 21)  )
            setDeviceValue(bytes.slice(0, 21));
            bytes = bytes.slice(21);
          }

          // if the stream is finished, exit the loop
          if (done) {
            // if there are any bytes left in the buffer, pass them to setDeviceValue
            if (bytes.length > 0) {
              // console.log("Method CALLLLLL 1----------", bytes.length  )
              // console.log("DATA CALLLLLL1----------", bytes.slice(0, 21)  )
              setDeviceValue(bytes);
            }
            break;
          }
        }
      } catch (error) {
        console.log(error);
        // Handle |error|...
      } finally {
        readerRef.releaseLock();
      }
    }
    await Ports.close();
  }
  function navigateToModeSelectionPage() {
    if (connectionEstablished && !getDataClicked) Ports.close();
    // User clicked a button to close the serial port.
    if (getDataClicked) {
      keepReading = false;
      // Force reader.read() to resolve immediately and subsequently
      // call reader.releaseLock() in the loop example above.
      readerRef.cancel();
    }
    setNavigateBack(true);
  }
  function getBitValueFor24PlusS(byteV,
    start_bitPos,
    stop_bitPos) {
    let ex_byteV = 255
    let ex_bitPos = 0
    let valueofFucniton = getBitDecimalValue(byteV, start_bitPos, stop_bitPos, ex_byteV, ex_bitPos)
    //  console.log(valueofFucniton)
    return valueofFucniton
  }
  function getBitDecimalValue(byteV, start_bitPos, stop_bitPos, ex_byteV, ex_bitPos) {
    let L_bytedec = 0;
    let L_power = 0;
    let i = 0;
    if (ex_byteV != 255) {
      L_bytedec = (bitShift(ex_byteV, ex_bitPos) * (Math.pow(2, L_power)));
      L_power++;
    }
    for (i = start_bitPos; i <= stop_bitPos; i++) {
      L_bytedec += (bitShift(byteV, i) * (Math.pow(2, L_power)));
      L_power++;
    }
    return L_bytedec;
    // console.log('byteeeeeeeeeeeeerrrrrrrrrrrrrrrrr---------------->', L_bytedec);
  }



  const byteArrayToInt4 = (byteArray) => {
    let out =
      (byteArray[0] << 24) |
      (byteArray[1] << 16) |
      (byteArray[2] << 8) |
      byteArray[3];
    return out;
  };
  const byteArrayToInt2 = (byteArray) => {
    let out = (byteArray[0] << 8) | byteArray[1];
    out = (out << 16) >> 16;
    return out;
  };
  const byteArrayTounsignedInt2 = (byteArray) => {
    let out = (byteArray[0] << 8) | byteArray[1];
    // out = (out << 16) >> 16;
    return out;
  };
  const byteArrayToInt1 = (byteArray) => {
    let out;
    out = (byteArray << 24) >> 24;
    return out;
  };
  const byteArrayToUnsignedInt1 = (byteArray) => {
    let out;
    out = (byteArray << 24) >>> 24;
    return out;
  };
  const bitShift = (byte, bitPosition) => {
    let x = (byte >> bitPosition) & 0x01;
    return x;
  };
  const integerTo4ByteArray = (integer) => {
    let out = [];
    out[3] = (integer >> 24) & 0xff;
    out[2] = (integer >> 16) & 0xff;
    out[1] = (integer >> 8) & 0xff;
    out[0] = integer & 0xff;
    return out;
  };
  const integerTo2ByteArray = (integer) => {
    let out = [];
    out[1] = (integer >> 8) & 0xff;
    out[0] = integer & 0xff;
    return out;
  };
  const xModemCrcFunction = (ptr, count) => {
    let crc = 0;
    let i = 0;
    let j = 0;
    while (--count >= 0) {
      crc = crc ^ (ptr[j] << 8);
      i = 8;
      do {
        if (crc & 0x8000) crc = (crc << 1) ^ 0x1021;
        else crc = crc << 1;
      } while (--i);
      j++;
    }
    return crc;
  };
  function CommandCenter(canId, messageId, dataArray) {
    cmd1 = 0;
    cmd2 = 0;
    let commandArray = [];
    commandArray[0] = 0x2a; //'*';
    commandArray[1] = 0x26; //'&';
    commandArray[2] = 0x24; //'$';
    commandArray[3] = messageId;
    commandArray[4] = canId[0];
    commandArray[5] = canId[1];
    commandArray[6] = canId[2];
    commandArray[7] = canId[3];
    commandArray[8] = 8;
    commandArray[9] = dataArray[0];
    commandArray[10] = dataArray[1];
    commandArray[11] = dataArray[2];
    commandArray[12] = dataArray[3];
    commandArray[13] = dataArray[4];
    commandArray[14] = dataArray[5];
    commandArray[15] = dataArray[6];
    commandArray[16] = dataArray[7];
    let crc_16Variable = [
      commandArray[0],
      commandArray[1],
      commandArray[2],
      commandArray[3],
      commandArray[4],
      commandArray[5],
      commandArray[6],
      commandArray[7],
      commandArray[8],
      commandArray[9],
      commandArray[10],
      commandArray[11],
      commandArray[12],
      commandArray[13],
      commandArray[14],
      commandArray[15],
      commandArray[16],
    ];
    commandArray[17] = uint16_t_crc_16(crc_16Variable, 17) & 0xff;
    commandArray[18] = uint16_t_crc_16(crc_16Variable, 17);
    commandArray[18] = (commandArray[18] >> 8) & 0xff;
    commandArray[19] = 0x0d;
    commandArray[20] = 0x0a;
    // console.log("commandArray", commandArray);
    Send(commandArray);
  }
  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  const uint16_t_crc_16 = (buffer, length) => {
    let CRC_16 = 0x0ffff;
    let L_Data = 0;
    for (let i = 0; i < length; i++) {
      L_Data = buffer[i];
      for (let j = 0; j < 8; j++) {
        if (((L_Data ^ CRC_16) & 1) === 1) {
          CRC_16 = (CRC_16 >> 1) ^ 40961;
        } else {
          CRC_16 = CRC_16 >> 1;
        }
        L_Data = L_Data >> 1;
      }
    }
    return CRC_16;
  };
  const CANbaudrateCommandSend = (second) => {
    if (!CANBaudRate) alert("Select Correct baud rate");
    else {
      let dataArray = [CANBaudRate, 0, 0, 0, 0, 0, 0, 0];
      let canId = integerTo4ByteArray(974256);
      CommandCenter(canId, 2, dataArray);
    }
  };
  const CANBmsResetCommandSend = (second) => {
    CANLoginCommandSend();
    let dataArray = [0x41, 0x44, 0x54, 0x26, 0x41, 0x00, 0x00, 0x00];
    let canId = integerTo4ByteArray(512496474);
    CommandCenter(canId, 1, dataArray);
  };
  const CANLoginCommandSend = (second) => {
    let dataArray = [0x31, 0x32, 0x33, 0x34, 0x35, 0x36, 0x37, 0x38];
    let canId = integerTo4ByteArray(512496471);
    CommandCenter(canId, 1, dataArray);
  };
  const clusterCANLoginCommandSend = (second) => {
    let dataArray = [0x43, 0x4C, 0x55, 0x53, 0x32, 0x34, 0x41, 0x43];
    let canId = integerTo4ByteArray(512496471);
    CommandCenter(canId, 1, dataArray);
  };
  const ChargerCANLoginCommandSend = (second) => {
    let dataArray = [0x41, 0x42, 0x43, 0x30, 0x30, 0x30, 0x30, 0x31];
    let canId = integerTo4ByteArray(512496471);
    CommandCenter(canId, 1, dataArray);
  };
  const CANTimeSyncCommandSend = () => {
    const d = new Date();
    let hour = d.getHours().toString(16);
    let minute = d.getMinutes().toString(16);
    let second = d.getSeconds().toString(16);
    let month = d.getMonth().toString(16);
    let year = d.getFullYear().toString(16);
    let date = d.getDate().toString(16);

    let dataArray = [year, month, date, hour, minute, second, 0x00, 0x00];
    // console.log('dateTime', dataArray)
    let canId = integerTo4ByteArray(512496492);
    CommandCenter(canId, 1, dataArray);
  };
  const ChargerCANBootLoaderCommandSend = (second) => {
    setFotaProcess(
      "Switching to Boot Loader mode, Please make sure Baud Rate is set to 250 kbps"
    );
    let dataArray = [0x41, 0x42, 0x43, 0x26, 0x70, 0x00, 0x00, 0x00];
    let canId = integerTo4ByteArray(512496474);
    CommandCenter(canId, 1, dataArray);
  };
  const CANBootLoaderCommandSend = (second) => {
    setFotaProcess(
      "Switching to Boot Loader mode, Please make sure Baud Rate is set to 250 kbps"
    );
    let dataArray = [0x41, 0x44, 0x54, 0x26, 0x70, 0x00, 0x00, 0x00];
    let canId = integerTo4ByteArray(512496474);
    CommandCenter(canId, 1, dataArray);
  };
  const clusterCANBootLoaderCommandSend = (second) => {
    setFotaProcess(
      "Switching to Boot Loader mode, Please make sure Baud Rate is set to 250 kbps"
    );
    let dataArray = [0x43, 0x4C, 0x55, 0x26, 0x70, 0x00, 0x00, 0x00];
    let canId = integerTo4ByteArray(512496474);
    CommandCenter(canId, 1, dataArray);
  };
  const CreateDataPackets = (cmd1, cmd2) => {
    // stopCommandCenter = false
    console.log("cmd1", cmd1);
    console.log("cmd2", cmd2);
    if (cmd1 === 48 && cmd2 === 72) {
      incrementXModem = 0;
      setFotaProcess("In Boot Loader mode");
      //Bootloader mode
      CANDataPacketCommandSend(0x48);
    } else if (cmd1 === 48 && cmd2 === 74) {
      // setFotaProcess("In Flash mode");
      setFotaProcess("Step 1");
      //Flash mode
      CANDataPacketCommandSend(0x31);
    } else if (cmd1 === 48 && cmd2 === 75) {
      // setFotaProcess("Blank Check Completed");
      setFotaProcess("Step 2");
      //Complete Blank Check
      CANDataPacketCommandSend(0x32);
    } else if (cmd1 === 48 && cmd2 === 76) {
      // setFotaProcess("Erasing Complete");
      setFotaProcess("Step 3");
      //Complete Erasing
      if (fileUploaded) CANDataPacketCommandSend(0x33);
      // if () CANDataPacketCommandSend(0x33);
      else
        setFotaProcess(
          "No Compatible File Found, please upload correct bin file to continue"
        );
    } else if (cmd1 === 48 && cmd2 === 78) {
      // setFotaProcess("Entering in xModem mode");
      setFotaProcess("Step 4");
      //Enter in xModem mode
      waitForXmodem = true;
    } else if (cmd1 === 67 && cmd2 === 78) {
      // setFotaProcess("Step 6 : Uploading xModem packets");
      setFotaProcess("Step 5");
      //Waiting for xModem packets
      xModemDataPacketCreation(incrementXModem);
    } else if (cmd1 === 6 && cmd2 === 78) {
      if (incrementXModem < xModem128ByteChunk.length - 1) {
        //ACK for valid xModem packet
        // console.log("INcrmenting XMODEM-------------->", incrementXModem);
        xModemDataPacketCreation(++incrementXModem);
      } else {
        // console.log("END PACKET XMODEM-------------->");
        if (!stopCommandCenter) {
          // setFotaProcess("Transmitting EOT");
          setFotaProcess("Finalizing Upload");
          let canId = integerTo4ByteArray(512496493);
          let dataArray = [0x04, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00];
          CommandCenter(canId, 1, dataArray);
          // console.log("EOT send command");
          stopCommandCenter = true;
        }
      }
    } else if (cmd1 === 21 && cmd2 === 78) {
      // console.log("NACK PACKET XMODEM-------------->");
      //NACK for invalid xModem packet
      if (fileUploaded) xModemDataPacketCreation(incrementXModem);
    } else if (cmd1 === 48 && cmd2 === 80) {
      setFotaProcess("Upload Complete");
      //Download Ok
      CANDataPacketCommandSend(0x34);
      // console.log("firmware update file upload complete");
    } else if (cmd1 === 48 && cmd2 === 81) {
      setFotaProcess("Flash Program Failed...Retrying...");
      //Flash Program Fail
    } else if (cmd1 === 48 && cmd2 === 79) {
      setFotaProcess("Timeout...Retrying...");
      //Timeout response
    } else if (cmd1 === 48 && cmd2 === 83) {
      setFotaProcess("Restarting the process");
      //Restart the whole process
    } else if (cmd1 === 48 && cmd2 === 82) {
      setFotaProcess("Process Complete");
      //Response for switch to main code cmd
      setFotaSuccessful(true);
      // setInFotaMode(false);
      console.log("fota successfull");
    } else console.log("Unknown command");
  };
  async function xModemDataPacketCreation(val) {
    let dataPacket = [];
    let xModemData = [];
    let i = 0;
    let j = 0;
    let k = 0;
    dataPacket.push(0x01);
    dataPacket.push(packetNumber[val]);
    dataPacket.push(255 - packetNumber[val]);
    dataPacket = [...dataPacket, ...xModem128ByteChunk[val]];
    let xModemCrc = xModemCrcFunction(xModem128ByteChunk[val], 128);
    dataPacket.push((xModemCrc >> 8) & 0xff);
    dataPacket.push(xModemCrc & 0xff);
    //console.log("dataPacket", dataPacket)
    console.log([].map.call(dataPacket, (x) => x.toString(16)));
    const chunkSize = 8;
    for (i = 0; i < dataPacket.length; i += chunkSize) {
      xModemData.push(dataPacket.slice(i, i + chunkSize));
    }
    // console.log("xModemData", xModemData);
    for (k = 0; k < xModemData.length; k++) {
      if (xModemData[k].length < 8) {
        let count_diff = 8 - xModemData[k].length;
        for (j = 0; j < count_diff; j++) {
          xModemData[k].push(0);
        }
      }
    }
    // console.log("xModemData", xModemData);
    let canId = integerTo4ByteArray(512496493);
    for (i = 0; i < xModemData.length; i++) {
      // console.log("xModemData[i]", xModemData[i]);
      // console.log("iiiiiii", i);
      // await sleep(10);
      if (i === 0) await sleep(2);
      else await sleep(2);

      // console.log([].map.call(xModemData[i], (x) => x.toString(16)));
      CommandCenter(canId, 1, xModemData[i]);
    }
  }
  const CANDataPacketCommandSend = (val) => {
    let dataArray = [val, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00];
    let canId = integerTo4ByteArray(512496495);
    CommandCenter(canId, 1, dataArray);
  };
  const UpdateCode = (val) => {
    if (val === 'charger') {
      ChargerCANLoginCommandSend();
      const myTimeout = setTimeout(ChargerCANBootLoaderCommandSend, 100);
    }
    else if(val === 'cluster'){
      clusterCANLoginCommandSend();
      const myTimeout = setTimeout(clusterCANBootLoaderCommandSend, 100);
    }
    else {
      CANLoginCommandSend();
      const myTimeout = setTimeout(CANBootLoaderCommandSend, 100);
    }

  };
  const BinFileInput = (event) => {
    setFileUploaded(true);
    const file = event.target.files[0];
    let fileSize = file.size;
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        packetNumber = [];
        xModem128ByteChunk = [];
        // resolve(event.target.result);
        // console.log("Here");
        let FileArray = new Uint8Array(event.target.result);
        // console.log("fileArray", FileArray);
        // console.log([].map.call(FileArray, (x) => x.toString(16)));
        if (fileSize % 128 === 0) {
          const chunkSize = 128;
          for (let i = 0; i < fileSize; i += chunkSize) {
            xModem128ByteChunk.push(FileArray.slice(i, i + chunkSize));
          }
          for (let i = 1; i <= xModem128ByteChunk.length; i++) {
            packetNumber.push(i % 256);
          }
        } else {
          for (let i = 0; i < fileSize - (fileSize % 128); i++) {
            FileArray.push("ff");
          }
          const chunkSize = 128;
          for (let i = 0; i < fileSize; i += chunkSize) {
            xModem128ByteChunk.push(FileArray.slice(i, i + chunkSize));
          }
          for (let i = 1; i <= xModem128ByteChunk.length; i++) {
            packetNumber.push(i % 256);
          }
        }
        // UpdateCode();
      };

      reader.onerror = (err) => {
        reject(err);
      };

      reader.readAsArrayBuffer(file);
    });
  };
  const handleChargerBaudRate = (event) => {
    console.log('event', event)
    setChargerBaudRate(parseInt(event.target.value))
  }
  const sendChargerBaudRate = () => {
    if (!ChargerBaudrate) alert("Select Correct baud rate");
    else {
      let dataArray = [ChargerBaudrate, 1, 1, 0, 0, 0, 0, 0];
      let canId = integerTo4ByteArray(285021694);
      CommandCenter(canId, 2, dataArray);
    }
  }
  const apiFOTAFileInput = (fileArray) => {
    setFileUploaded(true);
    const file = fileArray;
    let fileSize = file.length;
    return new Promise((resolve, reject) => {
      packetNumber = [];
      xModem128ByteChunk = [];
      // resolve(event.target.result);
      // console.log("Here");
      let FileArray = file;
      // console.log("fileArray", FileArray);
      // console.log([].map.call(FileArray, (x) => x.toString(16)));
      if (fileSize % 128 === 0) {
        const chunkSize = 128;
        for (let i = 0; i < fileSize; i += chunkSize) {
          xModem128ByteChunk.push(FileArray.slice(i, i + chunkSize));
        }
        for (let i = 1; i <= xModem128ByteChunk.length; i++) {
          packetNumber.push(i % 256);
        }
      } else {
        for (let i = 0; i < fileSize - (fileSize % 128); i++) {
          FileArray.push("ff");
        }
        const chunkSize = 128;
        for (let i = 0; i < fileSize; i += chunkSize) {
          xModem128ByteChunk.push(FileArray.slice(i, i + chunkSize));
        }
        for (let i = 1; i <= xModem128ByteChunk.length; i++) {
          packetNumber.push(i % 256);
        }
      }
      // UpdateCode();
    });
  };
  const handleBaudRateChange = (event) => {
    setCANBaudRate(parseInt(event.target.value));
  };
  const logOut = () => {
    window.localStorage.clear();
    window.location.href = "/";
  };
  async function sendConfig(value) {
    let index = 0;
    const array = [
      [
        0,
        value[index++],
        value[index++],
        value[index++],
        value[index++],
        value[index++],
        value[index++],
        value[index++],
      ],
      [
        1,
        value[index++],
        value[index++],
        value[index++],
        value[index++],
        value[index++],
        value[index++],
        value[index++],
      ],
      [
        2,
        value[index++],
        value[index++],
        value[index++],
        value[index++],
        value[index++],
        value[index++],
        value[index++],
      ],
      [
        3,
        value[index++],
        value[index++],
        value[index++],
        value[index++],
        value[index++],
        value[index++],
        value[index++],
      ],
      [
        4,
        value[index++],
        value[index++],
        value[index++],
        value[index++],
        value[index++],
        value[index++],
        value[index++],
      ],
      [
        5,
        value[index++],
        value[index++],
        value[index++],
        value[index++],
        value[index++],
        value[index++],
        value[index++],
      ],
      [
        6,
        value[index++],
        value[index++],
        value[index++],
        value[index++],
        value[index++],
        value[index++],
        value[index++],
      ],
      [
        7,
        value[index++],
        value[index++],
        value[index++],
        value[index++],
        value[index++],
        value[index++],
        value[index++],
      ],
      [
        8,
        value[index++],
        value[index++],
        value[index++],
        value[index++],
        value[index++],
        value[index++],
        value[index++],
      ],
      [
        9,
        value[index++],
        value[index++],
        value[index++],
        value[index++],
        value[index++],
        value[index++],
        value[index++],
      ],
      [
        10,
        value[index++],
        value[index++],
        value[index++],
        value[index++],
        value[index++],
        value[index++],
        value[index++],
      ],
      [
        11,
        value[index++],
        value[index++],
        value[index++],
        value[index++],
        value[index++],
        value[index++],
        value[index++],
      ],
      [
        12,
        value[index++],
        value[index++],
        value[index++],
        value[index++],
        value[index++],
        value[index++],
        value[index++],
      ],
      [
        13,
        value[index++],
        value[index++],
        value[index++],
        value[index++],
        value[index++],
        value[index++],
        value[index++],
      ],
      [
        14,
        value[index++],
        value[index++],
        value[index++],
        value[index++],
        value[index++],
        value[index++],
        value[index++],
      ],
      [
        15,
        value[index++],
        value[index++],
        value[index++],
        value[index++],
        value[index++],
        value[index++],
        value[index++],
      ],
      [
        16,
        value[index++],
        value[index++],
        value[index++],
        value[index++],
        value[index++],
        value[index++],
        value[index++],
      ],
      [
        17,
        value[index++],
        value[index++],
        value[index++],
        value[index++],
        value[index++],
        value[index++],
        value[index++],
      ],
      [
        18,
        value[index++],
        value[index++],
        value[index++],
        value[index++],
        value[index++],
        value[index++],
        value[index++],
      ],
      [
        19,
        value[index++],
        value[index++],
        value[index++],
        value[index++],
        value[index++],
        value[index++],
        value[index++],
      ],
      [
        20,
        value[index++],
        value[index++],
        value[index++],
        value[index++],
        value[index++],
        value[index++],
        value[index++],
      ],
      [
        21,
        value[index++],
        value[index++],
        value[index++],
        value[index++],
        value[index++],
        value[index++],
        value[index++],
      ],
      [
        22,
        value[index++],
        value[index++],
        value[index++],
        value[index++],
        value[index++],
        value[index++],
        0,
      ],
    ];
    for (let i = 0; i < 23; i++) {
      let canId = integerTo4ByteArray(216990112);
      CommandCenter(canId, 1, array[i]);
      await sleep(2);
    }
  }
  const setApiFotaFileInputHandler = (value) => {
    setApiFotaFileInput(value);
  };
  if (navigateBack) return <Redirect to="/mode-selection-page"></Redirect>
  else
    return (
      <div>
        <UncontrolledTooltip placement="bottom" target="switchCommMode1">
          Switch Communication Mode
        </UncontrolledTooltip>
        <Row>
          <span
            style={{ paddingLeft: "1rem", cursor: "pointer" }}
            onClick={navigateToModeSelectionPage}
            id="switchCommMode1"
          >
            <i class="fas fa-arrow-left"></i>&ensp;Mode Selection Page
          </span>
          <Col md="1" lg="1" sm="1" xs="1" xl="1"></Col>
          <Col md="10" lg="10" sm="10" xs="10" xl="10">
            <h3
              style={{
                justifyContent: "center",
                textAlign: "center",
                backgroundColor: "#6da2f7",
                margin: "15px 20%",
                borderRadius: "10px",
                borderColor: "#17a2b8",
                borderBottomStyle: "solid",
                borderWidth: "5px",
                color: "white",
                fontWeight: "bolder",
              }}
            >
              CAN
            </h3>
          </Col>
          <Col md="1" lg="1" sm="1" xs="1" xl="1">
            <h3
              style={{
                textAlign: "center",
                margin: "1rem 0 ",
                cursor: "pointer",
              }}
              onClick={logOut}
            >
              <i className="nc-icon nc-button-power"></i>
            </h3>
          </Col>
        </Row>
        <Row style={{ margin: "1rem", padding: "1rem", textAlign: "center" }}>
          <Col md="3" lg="3" sm="6" xs="6" xl="3">
            <Button onClick={RequestDevice} disabled={connectionEstablished}>
              Establish CAN tool connection
            </Button>
            {/* <button onClick={testing36S}>testing36S</button> */}
          </Col>
          <Col md="3" lg="3" sm="6" xs="6" xl="3">
            <select value={CANBaudRate} onChange={handleBaudRateChange}>
              <option value="">--Select Baudrate--</option>
              <option value={1}>125kbps</option>
              <option value={2}>250kbps</option>
              <option value={3}>500kbps</option>
              <option value={4}>1Mbps</option>
            </select>
            <Button
              onClick={CANbaudrateCommandSend}
              disabled={resetBmsDisabled}
            >
              Set Baud Rate
            </Button>
          </Col>
          <Col md="3" lg="3" sm="6" xs="6" xl="3">
            <Button onClick={Read} disabled={getDataDisabled}>
              Start Recieving Data from BMS
            </Button>
          </Col>
          <Col md="3" lg="3" sm="6" xs="6" xl="3">
            <Button
              onClick={CANBmsResetCommandSend}
              disabled={resetBmsDisabled}
            >
              Reset BMS
            </Button>
            <Button
              onClick={CANTimeSyncCommandSend}
              disabled={resetBmsDisabled}
            >
              Time Sync
            </Button>
          </Col>
        </Row>
        <DataHeader
          BatChar_1={BatChar_1}
          BatChar_2={BatChar_2}
          BatChar_3={BatChar_3}
          BatChar_4={BatChar_4}
          BatterySerialInt={BatterySerialInt}
          BMSModelID_Char1={BMSModelID_Char1}
          BMSModelID_Char2={BMSModelID_Char2}
          BMSModelID_Char3={BMSModelID_Char3}
          BMSModelID_Char4={BMSModelID_Char4}
          BMSModelID_Char5={BMSModelID_Char5}
          BMSModelID_Char6={BMSModelID_Char6}
          BMSModelID_Char7={BMSModelID_Char7}
          PDUHardwareVersion_Char1={PDUHardwareVersion_Char1}
          PDUHardwareVersion_Char2={PDUHardwareVersion_Char2}
          PDUHardwareVersion_Char3={PDUHardwareVersion_Char3}
          PDUHardwareVersion_Char4={PDUHardwareVersion_Char4}
          PDUHardwareVersion_Char5={PDUHardwareVersion_Char5}
          PDUHardwareVersion_Char6={PDUHardwareVersion_Char6}
          PDUHardwareVersion_Char7={PDUHardwareVersion_Char7}
          PDUHardwareVersion_Char8={PDUHardwareVersion_Char8}
          BMSHardwareVersion_Char1={BMSHardwareVersion_Char1}
          BMSHardwareVersion_Char2={BMSHardwareVersion_Char2}
          BMSHardwareVersion_Char3={BMSHardwareVersion_Char3}
          BMSHardwareVersion_Char4={BMSHardwareVersion_Char4}
          BMSHardwareVersion_Char5={BMSHardwareVersion_Char5}
          BMSHardwareVersion_Char6={BMSHardwareVersion_Char6}
          BMSHardwareVersion_Char7={BMSHardwareVersion_Char7}
          BMSHardwareVersion_Char8={BMSHardwareVersion_Char8}
          BMSFirmwareVersion_Char1={BMSFirmwareVersion_Char1}
          BMSFirmwareVersion_Char2={BMSFirmwareVersion_Char2}
          BMSFirmwareVersion_Char3={BMSFirmwareVersion_Char3}
          BMSFirmwareVersion_Char4={BMSFirmwareVersion_Char4}
          BMSFirmwareVersion_Char5={BMSFirmwareVersion_Char5}
          BMSFirmwareVersion_Char6={BMSFirmwareVersion_Char6}
          BMSFirmwareVersion_Char7={BMSFirmwareVersion_Char7}
          BMSFirmwareVersion_Char8={BMSFirmwareVersion_Char8}
          Year={Year}
          month={month}
          day={day}
          hour={hour}
          minutes={minutes}
          seconds={seconds}
          batteryPackId1={batteryPackId1}
          batteryPackId2={batteryPackId2}
        />
        <Row>
          <Col>
            <Card style={{ padding: "2rem", backgroundColor: "#e6ebed" }}>
              <Tab
                showUiFor24SPlus={showUiFor24SPlus}
                packetIndex={packetIndex}
                CellTempPacketIndex={CellTempPacketIndex}
                ExtrTempPacketIndex={ExtrTempPacketIndex}
                cellVoltageFor24SPlus={cellVoltageFor24SPlus}
                BMS_NoOfCellAvailable={BMS_NoOfCellAvailable}
                BMS_NoOfTempAvailable={BMS_NoOfTempAvailable}
                cellTempFor24SPlus={cellTempFor24SPlus}
                BMS_NoOfExterTempAvailable={BMS_NoOfExterTempAvailable}
                extrTempFor24SPlus={extrTempFor24SPlus}
                dataArray={dataArray}
                fetchConfigDisabled={fetchConfigDisabled}
                sendConfigDisabled={sendConfigDisabled}
                startUpdatingDisabled={startUpdatingDisabled}
                bmsHardwareVersion={bmsHardwareVersion}
                batterySerialArray={batterySerialArray}
                bmsModelId={bmsModelId}
                PDUHardwareVersion={PDUHardwareVersion}
                FirmwareVersion={FirmwareVersion}
                batteryVoltage={batteryVoltage}
                current={current}
                AvrgCellTemp={AvrgCellTemp}
                AvrgCellVolt={AvrgCellVolt}
                SOC={SOC}
                DisFET={DisFET}
                ChgFET={ChgFET}
                BattLow={BattLow}
                BattHigh={BattHigh}
                CellHigh={CellHigh}
                IGN_S={IGN_S}
                CellLow={CellLow}
                FullChargeBit={FullChargeBit}
                AvailableCapacity={AvailableCapacity}
                CyclesCount={CyclesCount}
                SOH={SOH}
                SOP={SOP}
                LowSOC_Indication={LowSOC_Indication}
                CAN_LoginStatusFlag={CAN_LoginStatusFlag}
                IgnitionOverCANFlag={IgnitionOverCANFlag}
                BalancingStatus={BalancingStatus}
                PDU_Temp={PDU_Temp}
                MaxCellVolt={MaxCellVolt}
                MinCellVolt={MinCellVolt}
                MaxCellTemp={MaxCellTemp}
                MinCellTemp={MinCellTemp}
                AverageCellVolt={AverageCellVolt}
                NumberOfCell={NumberOfCell}
                Cell1={Cell1}
                Cell2={Cell2}
                Cell3={Cell3}
                cell4={cell4}
                Cell5={Cell5}
                Cell6={Cell6}
                Cell7={Cell7}
                cell8={cell8}
                Cell9={Cell9}
                Cell10={Cell10}
                Cell11={Cell11}
                cell12={cell12}
                Cell13={Cell13}
                Cell14={Cell14}
                Cell15={Cell15}
                cell16={cell16}
                Cell17={Cell17}
                Cell18={Cell18}
                Cell19={Cell19}
                cell20={cell20}
                Cell21={Cell21}
                Cell22={Cell22}
                Cell23={Cell23}
                Cell24={Cell24}
                CellMaxMinDiff={CellMaxMinDiff}
                NumOfTemp={NumOfTemp}
                Temp1={Temp1}
                Temp2={Temp2}
                Temp3={Temp3}
                Temp4={Temp4}
                Temp5={Temp5}
                Temp6={Temp6}
                Temp7={Temp7}
                Temp8={Temp8}
                IC1Temp={IC1Temp}
                IC2Temp={IC2Temp}
                PDUTemp={PDUTemp}
                PCBTemp2={PCBTemp2}
                HeatSink1Temp={HeatSink1Temp}
                HeatSink2Temp={HeatSink2Temp}
                bmsPowerMode={bmsPowerMode}
                packCurrentState={packCurrentState}
                Alarm={Alarm}
                Fault={Fault}
                averageTimeToEmpty={averageTimeToEmpty}
                averageTimeToFull={averageTimeToFull}
                DisChargeMOSFETRx={DisChargeMOSFETRx}
                ChargeMOSFETRx={ChargeMOSFETRx}
                IgnitionOverCANFlagrx={IgnitionOverCANFlagrx}
                WarningFlags={WarningFlags}
                EnergyOut={EnergyOut}
                EnergyIn={EnergyIn}
                BMSConfigVersion_CRC16bit={BMSConfigVersion_CRC16bit}
                BMS_CommandChar1={BMS_CommandChar1}
                BMS_CommandChar2={BMS_CommandChar2}
                BMS_CommandChar3={BMS_CommandChar3}
                BMS_Command1={BMS_Command1}
                BMS_Command2={BMS_Command2}
                BMS_Command3={BMS_Command3}
                BMS_Command4={BMS_Command4}
                BMS_Command5={BMS_Command5}
                DEV_ON_OFF={DEV_ON_OFF}
                ISL_GLOBAL_FAULT={ISL_GLOBAL_FAULT}
                ISL_GLOBAL_EN={ISL_GLOBAL_EN}
                BMS_INGITION={BMS_INGITION}
                D_MOSFETs={D_MOSFETs}
                C_MOSFETs={C_MOSFETs}
                P_MOSFETs={P_MOSFETs}
                SCD_ALERT={SCD_ALERT}
                V5_ON_OFF={V5_ON_OFF}
                STATUS_PIN={STATUS_PIN}
                POWER_GOOD_PIN={POWER_GOOD_PIN}
                SetYear={SetYear}
                SetMonth={SetMonth}
                SetDay={SetDay}
                SetHour={SetHour}
                SetMinute={SetMinute}
                SetSecond={SetSecond}
                CANLoginCommandSend={CANLoginCommandSend}
                integerTo4ByteArray={integerTo4ByteArray}
                CommandCenter={CommandCenter}
                configurationArray={configurationArray}
                byteArrayToInt2={byteArrayToInt2}
                byteArrayToUnsignedInt1={byteArrayToUnsignedInt1}
                byteArrayTounsignedInt2={byteArrayTounsignedInt2}
                byteArrayToInt1={byteArrayToInt1}
                bitShift={bitShift}
                BinFileInput={BinFileInput}
                UpdateCode={UpdateCode}
                incrementXModem={incrementXModem}
                xModem128ByteChunk={xModem128ByteChunk}
                fotaSuccessful={fotaSuccessful}
                inFotaMode={inFotaMode}
                Year={Year}
                month={month}
                day={day}
                hour={hour}
                minutes={minutes}
                seconds={seconds}
                fotaProcess={fotaProcess}
                integerTo2ByteArray={integerTo2ByteArray}
                fetchConfiguration={fetchConfiguration}
                sendConfig={sendConfig}
                fileUploaded={fileUploaded}
                apiFOTAFile={apiFOTAFile}
                showLoaderInApiDownload={showLoaderInApiDownload}
                apiFileUploadSuccess={apiFileUploadSuccess}
                apiFotaFileSize={apiFotaFileSize}
                setApiFotaFileInputHandler={setApiFotaFileInputHandler}
                ChargerCANLoginCommandSend={ChargerCANLoginCommandSend}
                ChargerCANBootLoaderCommandSend={ChargerCANBootLoaderCommandSend}
                ChargerBaudrate={ChargerBaudrate}
                handleChargerBaudRate={handleChargerBaudRate}
                sendChargerBaudRate={sendChargerBaudRate}
                chargerDataRecieved={chargerDataRecieved}
                chargerStateDataRecieved={chargerStateDataRecieved}
                chargerDetailsDataRecieved={chargerDetailsDataRecieved}
                chargerACDCParameter1DataRecieved={chargerACDCParameter1DataRecieved}
              />
            </Card>
          </Col>
        </Row>
      </div>
    );
}

export default CANHome;
