import { Card, Table } from "reactstrap";
import { Col, Row } from "react-bootstrap";
import React from "react";
import Spinner from "reactstrap/lib/Spinner";
import { useState } from "react";
import { useRef } from "react";
import { useContext } from "react";
import {
  valueDataContext,
  faultDataContext,
  valueDataMContext,
  faultDataMContext,
} from "views/Dashboard";

function Faults(props) {
  const FaultArray = [
    "No Fault",
    "Over Pack Voltage Fault",
    "Over Pack Voltage",
    "Under Pack Voltage Fault",
    "Over OCD1 Current",
    "Over OCD2 Current",
    "Over Charge Current",
    "Over Discharge Current",
    "Short Circuit",
    "Cell Open Wire Fault",
    "Voltage Reference Fault",
    "Voltage Regulator Fault",
    "Temperature Multiplexer Error",
    "Under Temperature Fault",
    "Load Over Temperature",
    "Oscillator Fault",
    "Watchdog Timeout Fault",
    "Under Cell Voltage Fault",
    "Over Cell Voltage Fault",
    "Open Wire Fault",
    "Over Temperature Fault",
    "Cell Unbalance Pro",
    "SCD Latch High",
    "SPI Communication Fault",
    "I2C Communication Fault",
    "MCU Reset Fault",
    "Data CRC Error Fault",
    "Data Ready Fault",
    "Charger Authentication Failed",
    "Thermal Runaway Due To Temperature",
    "Thermal Runaway Due To Voltage",
    "MOSFET Drive Voltage Fault",
    "Reserve Condition Reached",
    "Battery Empty Reached",
    "Battery Full Charged Reached",
    "Weld Check Fault",			//35
    "Fuse Burn Fault",			//36
    "PCU Over Temp Fault",
    "Cell Temperature Imbalance Fault", 	//38
    "Precharge Complete Fault"
  ];
  const valueDataM = useContext(valueDataMContext);
  let tryArray = [];
  const faultDataM = useContext(faultDataMContext);
  if (faultDataM) tryArray.push(faultDataM.ext[0].bmsFault);
  // console.log('tryArray', tryArray)

  let faultDataItem = useContext(faultDataContext);
  let valueDataItem = props.valueDataItem
  if (faultDataItem && valueDataItem) {
    let faultArray = [];
    for (let i = 0; i < faultDataItem.bmsValues.length; i++)
    // for (let i = 0; i < (faultDataItem.bmsValues.length < 10?faultDataItem.bmsValues.length:10); i++)
  {
    if(faultDataItem.bmsValues[i].bmsfault < FaultArray.length)
    faultArray[i] = faultDataItem.bmsValues[i];
  }
    return (
      <div>
        <Card>
          {valueDataM ? (
            <Row>
              <Col md="10" sm="10" xs="10" lg="10">
                <div style={{ marginTop: "10px", padding: "2px" }}>
                  <strong>Last Fault:</strong>&ensp;
                  <strong style={{ color: "rgb(48, 66, 140)" }}>
                    {FaultArray[valueDataM.ext[0].cellTempData.lastFault]}
                  </strong>
                </div>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col md="10" sm="10" xs="10" lg="10">
                <div style={{ marginTop: "10px", padding: "2px" }}>
                  <strong>Last Fault:</strong>&ensp;
                  <strong style={{ color: "rgb(48, 66, 140)" }}>
                    {FaultArray[valueDataItem.lastFault]}
                  </strong>
                </div>
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <Table responsive style={{ display: "block", height: "60px" }}>
                <tbody>
                  {faultArray.map((val) => (
                    <tr>
                      <td>
                        <strong style={{ color: "rgb(48, 66, 140)" }}>
                          {val.tms ? val.tms.slice(11, 19) : "-"}
                        </strong>
                      </td>
                      <td>
                        <strong style={{ color: "rgb(48, 66, 140)" }}>
                          {val.tms ? val.tms.slice(8, 10) : "-"}/
                          {val.tms ? val.tms.slice(5, 7) : "-"}/
                          {val.tms ? val.tms.slice(0, 4) : "-"}
                        </strong>
                      </td>
                      <td>
                        <strong style={{ color: "rgb(48, 66, 140)" }}>
                          {FaultArray[val.bmsfault]}
                        </strong>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Card>
      </div>
    );
  } else {
    return (
      <div>
        <Spinner>Loading...</Spinner>
      </div>
    );
  }
}
export default Faults;
