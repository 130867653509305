import React, { useEffect, useState, useContext } from 'react'
import { Bar, Doughnut, Line, Pie } from 'react-chartjs-2'
import { Card, Col, Row, UncontrolledTooltip } from 'reactstrap'
import { API_URL } from 'variables/general'
import Nodatagraph from './Nodatagraph.png'
import { bmsIdContext, homeBmsValuesContext } from 'views/Dashboard'
// import html2canvas from 'html2canvas';
// import jsPDF from 'jspdf';
function LifeTimeAnalysis() {
    useEffect(() => {
        getLtAnalysisData()
    }, [])
    const batteryPackId = useContext(bmsIdContext)
    const valueData = useContext(homeBmsValuesContext)
    let cycleCount
    let cycleCountDate
    if (valueData) {
        cycleCount = valueData.cycleCount
        cycleCountDate = valueData.tms
    }
    const [ltAnalysisData, setLtAnalysisData] = useState()
    const [energyData, setEnergyData] = useState()
    const [ltData, setLtData] = useState(false)
    const [cdProfileData, setCdProfileData] = useState()

    function getLtAnalysisData(payload) {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append(
            "Authorization",
            "Bearer " + window.localStorage.getItem('token')
        );
        var requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };
        fetch(API_URL + "/bms/v2/lt_analysis?batteryPackId=" + batteryPackId, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                setLtData(true)
                setLtAnalysisData(result.data.analysisValues.data)
                let energyData = result.data.energyData.sort((a, b) => new Date(a.tms) - new Date(b.tms))
                setEnergyData(energyData)
                // setAnalysisData(result.data)
            })
            .catch((error) => console.log("error", error));
    }
    function getCDProfile(payload) {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append(
            "Authorization",
            "Bearer " + window.localStorage.getItem('token')
        );
        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            redirect: "follow",
            body: JSON.stringify({
                batteryPackId: "ABT865577074929713",
                tms: "2024-05-08T00:00:00Z",
                tms_lt: "2024-05-10T00:00:00Z"
            })
        };
        fetch(API_URL + "/bms/v2/cd_profile", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                setCdProfileData(result.data.cdCycles)
                // setAnalysisData(result.data)
            })
            .catch((error) => console.log("error", error));
    }
    const FaultArray = [
        "No Fault",
        "Over Pack Voltage Fault",
        "Over Pack Voltage",
        "Under Pack Voltage Fault",
        "Over OCD1 Current",
        "Over OCD2 Current",
        "Over Charge Current",
        "Over Discharge Current",
        "Short Circuit",
        "Cell Open Wire Fault",
        "Voltage Reference Fault",
        "Voltage Regulator Fault",
        "Temperature Multiplexer Error",
        "Under Temperature Fault",
        "Load Over Temperature",
        "Oscillator Fault",
        "Watchdog Timeout Fault",
        "Under Cell Voltage Fault",
        "Over Cell Voltage Fault",
        "Open Wire Fault",
        "Over Temperature Fault",
        "Cell Unbalance Pro",
        "SCD Latch High",
        "SPI Communication Fault",
        "I2C Communication Fault",
        "MCU Reset Fault",
        "Data CRC Error Fault",
        "Data Ready Fault",
        "Charger Authentication Failed",
        "Thermal Runaway Due To Temperature",
        "Thermal Runaway Due To Voltage",
        "MOSFET Drive Voltage Fault",
        "Reserve Condition Reached",
        "Battery Empty Reached",
        "Battery Full Charged Reached",
        "Weld Check Fault",			//35
        "Fuse Burn Fault",			//36
        "PCU Over Temp Fault",
        "Cell Temperature Imbalance Fault", 	//38
        "Precharge Complete Fault"
    ]
    const backgroundColor = [
        '#FF5733', //(Red Orange)
        '#33FF57', //(Green)
        '#3357FF', //(Blue)
        '#FF33A8', //(Pink)
        '#33FFF5', //(Cyan)
        '#F5FF33', //(Yellow)
        '#8D33FF', //(Purple)
        '#FF8333', //(Orange)
        '#33FF8D', //(Light Green)
        '#337FFF', //(Light Blue)
        '#FF338D', //(Magenta)
        '#33F5FF', //(Aqua)
        '#FFF533', //(Light Yellow)
        '#BD33FF', //(Violet)
        '#FFBD33', //(Peach)
        '#33FFBD', //(Mint)
        '#33B8FF', //(Sky Blue)
        '#FF33BD', //(Hot Pink)
        '#33FF8D', //(Lime)
        '#FFD433', //(Gold)
        '#3366FF', //(Royal Blue)
        '#FF3366', //(Rose)
        '#33FFF5', //(Turquoise)
        '#F5FF33', //(Lemon)
        '#A833FF', //(Indigo)
        '#FF8D33', //(Coral)
        '#33FF83', //(Spring Green)
        '#33A8FF', //(Azure)
        '#FF33A8', //(Fuchsia)
        '#33F5BD', //(Sea Green)
        '#FFF533', //(Sunflower)
        '#6B33FF', //(Purple Blue)
        '#FF6B33', //(Tangerine)
        '#33FF6B', //(Electric Green)
        '#33B8A8', //(Teal)
        '#FF83A8', //(Light Pink)
    ]
    function getToolTipDate(date) {
        let newDate = date.split('T')[0].split('-')[2] + '/' + date.split('T')[0].split('-')[1] + '/' + date.split('T')[0].split('-')[0]
        return newDate
    }
    if(ltData)
    {
        if (ltAnalysisData && energyData)    
      {
        if((energyData.length > 0) && (ltAnalysisData !=null))
        {
        const labelObj = Object.keys(ltAnalysisData.ltFaultCount)
        let updatedLabel = []
        labelObj.forEach(element => {
            updatedLabel.push(FaultArray[parseInt(element)])
        });
        const data = Object.values(ltAnalysisData.ltFaultCount)
        let totalFaultCount = 0
        data.forEach(val => {
            totalFaultCount += val
        })
        console.log('totalFaultCount', totalFaultCount)
        const lifeTimeFaultChart = {
            data: () => {
                return {
                    labels: updatedLabel,
                    datasets: [
                        {
                            label: "Faults",
                            pointRadius: 0,
                            pointHoverRadius: 0,
                            backgroundColor: backgroundColor,
                            data: data,
                        },
                    ],
                };
            },
            options: {
                legend: {
                    display: false,
                },
                pieceLabel: {
                    render: "percentage",
                    fontColor: ["white"],
                    precision: 2,
                },
                tooltips: {
                    // enabled: false,
                },
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                display: false,
                            },
                            gridLines: {
                                drawBorder: false,
                                zeroLineColor: "transparent",
                                color: "rgba(255,255,255,0.00)",
                            },
                        },
                    ],
                    xAxes: [
                        {
                            barPercentage: 1,
                            gridLines: {
                                // drawBorder: false,
                                color: "rgba(255,255,255,0.0)",
                                zeroLineColor: "transparent",
                            },
                            ticks: {
                                display: false,
                            },
                        },
                    ],
                },
            },
        };

        //ltUCVFault
        const ltUCVFaultLabel = Object.keys(ltAnalysisData.ltUcvFault.data)
        let ltUCVFaultLabelUpdated = []
        ltUCVFaultLabel.forEach((element, index) => {
            ltUCVFaultLabelUpdated.push('Cell ' + element)
        })
        const ltUCVFaultData = Object.values(ltAnalysisData.ltUcvFault.data)
        const ltUCVFaultChart = {
            data: () => {
                return {
                    labels: ltUCVFaultLabelUpdated,
                    datasets: [
                        {
                            label: "Count",
                            data: ltUCVFaultData,
                            pointRadius: 0,
                            pointHoverRadius: 0,
                            backgroundColor: backgroundColor,
                        },
                    ],
                };
            },
            options: {
                legend: {
                    // display: false,
                },
                pieceLabel: {
                    render: "percentage",
                    fontColor: ["white"],
                    precision: 2,
                },
                tooltips: {
                    // enabled: false,
                },
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                display: false,
                            },
                            gridLines: {
                                drawBorder: false,
                                zeroLineColor: "transparent",
                                color: "rgba(255,255,255,0.00)",
                            },
                        },
                    ],
                    xAxes: [
                        {
                            barPercentage: 1,
                            gridLines: {
                                // drawBorder: false,
                                color: "rgba(255,255,255,0.0)",
                                zeroLineColor: "transparent",
                            },
                            ticks: {
                                display: false,
                            },
                        },
                    ],
                },
            },
        };
        //ltOCVFault
        const ltOCVFaultLabel = Object.keys(ltAnalysisData.ltOcvFault.data)
        let ltOCVFaultLabelUpdated = []
        ltOCVFaultLabel.forEach((element, index) => {
            ltOCVFaultLabelUpdated.push('Cell ' + element)
        })
        const ltOCVFaultData = Object.values(ltAnalysisData.ltOcvFault.data)
        const ltOCVFaultChart = {
            data: () => {
                return {
                    labels: ltOCVFaultLabelUpdated,
                    datasets: [
                        {
                            label: "Count",
                            data: ltOCVFaultData,
                            pointRadius: 0,
                            pointHoverRadius: 0,
                            backgroundColor: backgroundColor,
                        },
                    ],
                };
            },
            options: {
                legend: {
                    // display: false,
                },
                pieceLabel: {
                    render: "percentage",
                    fontColor: ["white"],
                    precision: 2,
                },
                tooltips: {
                    // enabled: false,
                },
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                display: false,
                            },
                            gridLines: {
                                drawBorder: false,
                                zeroLineColor: "transparent",
                                color: "rgba(255,255,255,0.00)",
                            },
                        },
                    ],
                    xAxes: [
                        {
                            barPercentage: 1,
                            gridLines: {
                                // drawBorder: false,
                                color: "rgba(255,255,255,0.0)",
                                zeroLineColor: "transparent",
                            },
                            ticks: {
                                display: false,
                            },
                        },
                    ],
                },
            },
        };
        //ltOverCellTempChargingObj
        const ltOverCellTempChargingObjLabel = Object.keys(ltAnalysisData.ltOverCellTempChargingObj.data)
        let ltOverCellTempChargingObjLabelUpdated = []
        ltOverCellTempChargingObjLabel.forEach((element, index) => {
            ltOverCellTempChargingObjLabelUpdated.push('Zone ' + element)
        })
        const ltOverCellTempChargingObjData = Object.values(ltAnalysisData.ltOverCellTempChargingObj.data)
        const ltOverCellTempChargingObjChart = {
            data: () => {
                return {
                    labels: ltOverCellTempChargingObjLabelUpdated,
                    datasets: [
                        {
                            label: "Count",
                            data: ltOverCellTempChargingObjData,
                            pointRadius: 0,
                            pointHoverRadius: 0,
                            backgroundColor: backgroundColor,
                        },
                    ],
                };
            },
            options: {
                legend: {
                    // display: false,
                },
                pieceLabel: {
                    render: "percentage",
                    fontColor: ["white"],
                    precision: 2,
                },
                tooltips: {
                    // enabled: false,
                },
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                display: false,
                            },
                            gridLines: {
                                drawBorder: false,
                                zeroLineColor: "transparent",
                                color: "rgba(255,255,255,0.00)",
                            },
                        },
                    ],
                    xAxes: [
                        {
                            barPercentage: 1,
                            gridLines: {
                                // drawBorder: false,
                                color: "rgba(255,255,255,0.0)",
                                zeroLineColor: "transparent",
                            },
                            ticks: {
                                display: false,
                            },
                        },
                    ],
                },
            },
        };
        //ltOverCellTempDisChargingObj
        const ltOverCellTempDisChargingObjLabel = Object.keys(ltAnalysisData.ltOverCellTempDisChargingObj.data)
        let ltOverCellTempDisChargingObjLabelUpdated = []
        ltOverCellTempDisChargingObjLabel.forEach((element, index) => {
            ltOverCellTempDisChargingObjLabelUpdated.push('Zone ' + element)
        })
        const ltOverCellTempDisChargingObjData = Object.values(ltAnalysisData.ltOverCellTempDisChargingObj.data)
        const ltOverCellTempDisChargingObjChart = {
            data: () => {
                return {
                    labels: ltOverCellTempDisChargingObjLabelUpdated,
                    datasets: [
                        {
                            label: "Count",
                            data: ltOverCellTempDisChargingObjData,
                            pointRadius: 0,
                            pointHoverRadius: 0,
                            backgroundColor: backgroundColor,
                        },
                    ],
                };
            },
            options: {
                legend: {
                    // display: false,
                },
                pieceLabel: {
                    render: "percentage",
                    fontColor: ["white"],
                    precision: 2,
                },
                tooltips: {
                    // enabled: false,
                },
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                display: false,
                            },
                            gridLines: {
                                drawBorder: false,
                                zeroLineColor: "transparent",
                                color: "rgba(255,255,255,0.00)",
                            },
                        },
                    ],
                    xAxes: [
                        {
                            barPercentage: 1,
                            gridLines: {
                                // drawBorder: false,
                                color: "rgba(255,255,255,0.0)",
                                zeroLineColor: "transparent",
                            },
                            ticks: {
                                display: false,
                            },
                        },
                    ],
                },
            },
        };
        //ltFaultCount
        const ltFaultCountLabel = Object.keys(ltAnalysisData.ltFaultCount)
        let ltFaultCountLabelUpdated = []
        ltFaultCountLabel.forEach((element, index) => {
            ltFaultCountLabelUpdated.push(FaultArray[element])
        })
        const ltFaultCountData = Object.values(ltAnalysisData.ltFaultCount)
        const ltFaultCountChart = {
            data: () => {
                return {
                    labels: ltFaultCountLabelUpdated,
                    datasets: [
                        {
                            label: "Count",
                            data: ltFaultCountData,
                            backgroundColor: ['#36384c', '#613a43', '#97a989', '#eee3d6', '#e3b9b3',],
                            borderColor: [],
                            borderWidth: 0,
                        },
                    ],
                };
            },
            options: {
                legend: {
                    // display: false,
                },
                pieceLabel: {
                    render: "percentage",
                    fontColor: ["white"],
                    precision: 2,
                },
                tooltips: {
                    // enabled: false,
                },
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                display: false,
                            },
                            gridLines: {
                                drawBorder: false,
                                zeroLineColor: "transparent",
                                color: "rgba(255,255,255,0.00)",
                            },
                        },
                    ],
                    xAxes: [
                        {
                            barPercentage: 1,
                            gridLines: {
                                // drawBorder: false,
                                color: "rgba(255,255,255,0.0)",
                                zeroLineColor: "transparent",
                            },
                            ticks: {
                                display: false,
                            },
                        },
                    ],
                },
            },
        };
        //energyIn

        let energyInData = []
        let energyInLabel = []
        let energyOutData = []
        let energyOutLabel = []
        let energyInDataIncrement = []
        let initialEnergyIn = 0
        let initialEnergyOut = 0
        let energyOutDataIncrement = []
        energyData.forEach((element) => {
            initialEnergyIn += element.energyIn
            energyInDataIncrement.push(initialEnergyIn / 1000)
            initialEnergyOut += element.energyOut
            energyOutDataIncrement.push(initialEnergyOut / 1000)
            energyInData.push(element.energyIn / 1000)
            let time = element.tms.split('T')[0].split('-')[2] + '/' + element.tms.split('T')[0].split('-')[1]
            energyInLabel.push(time)
            energyOutData.push(element.energyOut / 1000)
            energyOutLabel.push(time)
        })
        const energyInChart =
        {
            data: {
                labels: energyInLabel,
                datasets: [
                    {
                        label: "Energy In (kW)",
                        data: energyInData,
                        fill: false,
                        borderColor: "#0ff",
                        backgroundColor: "transparent",
                        pointBorderColor: "#0ff",
                        pointRadius: 1,
                        pointHoverRadius: 10,
                        pointBorderWidth: 0,
                    },
                    {
                        label: "Energy Out (kW)",
                        data: energyOutData,
                        fill: false,
                        borderColor: "#f00",
                        backgroundColor: "transparent",
                        pointBorderColor: "#f00",
                        pointRadius: 1,
                        pointHoverRadius: 10,
                        pointBorderWidth: 0,
                    },
                ],
            },
            options: {
                elements: {
                    line: {
                        tension: 0.3,
                    },
                },
                scales: {
                    yAxes: [
                        {
                            // ticks: {
                            //     beginAtZero: false,
                            //     max: 100,
                            //     min: 0,
                            // },
                        },
                    ],
                },
                legend: {
                    display: true,
                    position: "top",
                },
            },
        };
        const energyOutChart =
        {
            data: {
                labels: energyOutLabel,
                datasets: [
                    {
                        label: "Energy Out (Ah)",
                        data: energyOutData,
                        fill: false,
                        borderColor: "#0ff",
                        backgroundColor: "transparent",
                        pointBorderColor: "#0ff",
                        pointRadius: 1,
                        pointHoverRadius: 10,
                        pointBorderWidth: 0,
                    },
                ],
            },
            options: {
                elements: {
                    line: {
                        tension: 0.3,
                    },
                },
                scales: {
                    yAxes: [
                        {
                            // ticks: {
                            //     beginAtZero: false,
                            //     max: 100,
                            //     min: 0,
                            // },
                        },
                    ],
                },
                legend: {
                    display: true,
                    position: "top",
                },
            },
        };
        const energyInIncrementChart =
        {
            data: {
                labels: energyInLabel,
                datasets: [
                    {
                        label: "Energy In (Ah)",
                        data: energyInDataIncrement,
                        fill: false,
                        borderColor: "#0ff",
                        backgroundColor: "transparent",
                        pointBorderColor: "#0ff",
                        pointRadius: 1,
                        pointHoverRadius: 10,
                        pointBorderWidth: 0,
                    },
                ],
            },
            options: {
                elements: {
                    line: {
                        tension: 0.3,
                    },
                },
                scales: {
                    yAxes: [
                        {
                            // ticks: {
                            //     beginAtZero: false,
                            //     max: 100,
                            //     min: 0,
                            // },
                        },
                    ],
                },
                legend: {
                    display: true,
                    position: "top",
                },
            },
        };
        const energyOutIncrementChart =
        {
            data: {
                labels: energyOutLabel,
                datasets: [
                    {
                        label: "Energy Out (kW)",
                        data: energyOutDataIncrement,
                        fill: false,
                        borderColor: "#0ff",
                        backgroundColor: "transparent",
                        pointBorderColor: "#0ff",
                        pointRadius: 1,
                        pointHoverRadius: 10,
                        pointBorderWidth: 0,
                    },
                ],
            },
            options: {
                elements: {
                    line: {
                        tension: 0.3,
                    },
                },
                scales: {
                    yAxes: [
                        {
                            // ticks: {
                            //     beginAtZero: false,
                            //     max: 100,
                            //     min: 0,
                            // },
                        },
                    ],
                },
                legend: {
                    display: false,
                    position: "top",
                },
            },
        };
        // const generatePdf = () => {
        //     const input = document.getElementById('pdfContent');
        //     const pdf = new jsPDF('p', 'mm', 'a4');
        //     const pdfWidth = pdf.internal.pageSize.getWidth();
        //     const pdfHeight = pdf.internal.pageSize.getHeight();
        //     const divHeight = input.scrollHeight;
        //     const canvasHeight = divHeight * pdfWidth / input.scrollWidth;
        //     const totalPDFPages = Math.ceil(divHeight / pdfHeight);
        
        //     html2canvas(input).then((canvas) => {
        //       const imgData = canvas.toDataURL('image/png');
        //       for (let i = 0; i < totalPDFPages; i++) {
        //         const position = -i * pdfHeight;
        //         pdf.addImage(imgData, 'PNG', 0, position, pdfWidth, canvasHeight);
        //         if (i < totalPDFPages - 1) {
        //           pdf.addPage();
        //         }
        //       }
        //       pdf.save('download.pdf');
        //     });
        //   };
        return (
            <div >
                <UncontrolledTooltip target="ltPeakChargingCurrent">
                    Instance Recorded On : {getToolTipDate(ltAnalysisData.ltPeakChargingCurrentDate)}
                </UncontrolledTooltip>
                <UncontrolledTooltip target="ltPeakDischargingCurrent">
                    Instance Recorded On : {getToolTipDate(ltAnalysisData.ltPeakDischargingCurrentDate)}
                </UncontrolledTooltip>
                <UncontrolledTooltip target="ltPeakRegen">
                    Instance Recorded On : {getToolTipDate(ltAnalysisData.ltPeakRegenDate)}
                </UncontrolledTooltip>
                <UncontrolledTooltip target="ltLowestVoltage">
                    Instance Recorded On : {getToolTipDate(ltAnalysisData.ltLowestVoltageDate)}
                </UncontrolledTooltip>
                <UncontrolledTooltip target="ltPeakPowerCharging">
                    Instance Recorded On : {getToolTipDate(ltAnalysisData.ltPeakPowerChargingDate)}
                </UncontrolledTooltip>
                <UncontrolledTooltip target="ltPeakPowerDischarging">
                    Instance Recorded On : {getToolTipDate(ltAnalysisData.ltPeakPowerDischargingDate)}
                </UncontrolledTooltip>
                <UncontrolledTooltip target="ltMaxCellTemp">
                    Instance Recorded On : {getToolTipDate(ltAnalysisData.ltMaxCellTempDate)}
                </UncontrolledTooltip>
                <UncontrolledTooltip target="ltMaxPduTemp">
                    Instance Recorded On : {getToolTipDate(ltAnalysisData.ltMaxPduTempDate)}
                </UncontrolledTooltip>
                <UncontrolledTooltip target="ltMaxPduTemp">
                    Instance Recorded On : {cycleCountDate ? getToolTipDate(cycleCountDate) : 'No Data'}
                </UncontrolledTooltip>
                {/* <div id='pdfContent'> */}
                <Row>
                    <Col xs="12" sm="12" md="3">
                        <Card className="hero-cards">
                            <div className="card-head"> Peak Current Charging </div>
                            <div className="card-foot">
                                {ltAnalysisData.ltPeakChargingCurrent / 1000} A&ensp;<i
                                    class="fa fa-xs fa-question-circle"
                                    aria-hidden="true"
                                    id="ltPeakChargingCurrent"
                                ></i>
                            </div>
                        </Card>
                    </Col>
                    <Col xs="12" sm="12" md="3">
                        <Card className="hero-cards">
                            <div className="card-head"> Peak Current Discharging </div>
                            <div className="card-foot">
                                {ltAnalysisData.ltPeakDischargingCurrent / 1000} A&ensp;
                                <i
                                    class="fa fa-xs fa-question-circle"
                                    aria-hidden="true"
                                    id="ltPeakDischargingCurrent"
                                ></i>
                            </div>
                        </Card>
                    </Col>
                    <Col xs="12" sm="12" md="3">
                        <Card className="hero-cards">
                            <div className="card-head"> Peak Current Regen </div>
                            <div className="card-foot">
                                {ltAnalysisData.ltPeakRegen / 1000} A&ensp;
                                <i
                                    class="fa fa-xs fa-question-circle"
                                    aria-hidden="true"
                                    id="ltPeakRegen"
                                ></i>
                            </div>
                        </Card>
                    </Col>
                    <Col xs="12" sm="12" md="3">
                        <Card className="hero-cards">
                            <div className="card-head"> Lowest Voltage Reached</div>
                            <div className="card-foot">
                                {ltAnalysisData.ltLowestVoltage / 1000} V&ensp;
                                <i
                                    class="fa fa-xs fa-question-circle"
                                    aria-hidden="true"
                                    id="ltLowestVoltage"
                                ></i>
                            </div>
                        </Card>
                    </Col>
                    <Col xs="12" sm="12" md="3">
                        <Card className="hero-cards">
                            <div className="card-head"> Peak Power Charging</div>
                            <div className="card-foot">
                                {ltAnalysisData.ltPeakPowerCharging > 1000 ? `${ltAnalysisData.ltPeakPowerCharging / 1000} kW` : `${ltAnalysisData.ltPeakPowerCharging} W`}&ensp;
                                <i
                                    class="fa fa-xs fa-question-circle"
                                    aria-hidden="true"
                                    id="ltPeakPowerCharging"
                                ></i>
                            </div>
                        </Card>
                    </Col>
                    <Col xs="12" sm="12" md="3">
                        <Card className="hero-cards">
                            <div className="card-head"> Peak Power Discharging</div>
                            <div className="card-foot">
                                {ltAnalysisData.ltPeakPowerDischarging > 1000 ? `${ltAnalysisData.ltPeakPowerDischarging / 1000} kW` : `${ltAnalysisData.ltPeakPowerDischarging} W`}&ensp;
                                <i
                                    class="fa fa-xs fa-question-circle"
                                    aria-hidden="true"
                                    id="ltPeakPowerDischarging"
                                ></i>
                            </div>
                        </Card>
                    </Col>
                    <Col xs="12" sm="12" md="3">
                        <Card className="hero-cards">
                            <div className="card-head"> Max Cell Temperature</div>
                            <div className="card-foot">
                                {ltAnalysisData.ltMaxCellTemp} °C&ensp;
                                <i
                                    class="fa fa-xs fa-question-circle"
                                    aria-hidden="true"
                                    id="ltMaxCellTemp"
                                ></i>
                            </div>
                        </Card>
                    </Col>
                    <Col xs="12" sm="12" md="3">
                        <Card className="hero-cards">
                            <div className="card-head"> Max PDU Temperature</div>
                            <div className="card-foot">
                                {ltAnalysisData.ltMaxPduTemp} °C&ensp;
                                <i
                                    class="fa fa-xs fa-question-circle"
                                    aria-hidden="true"
                                    id="ltMaxPduTemp"
                                ></i>
                            </div>
                        </Card>
                    </Col>
                    <Col xs="12" sm="12" md="3">
                        <Card className="hero-cards">
                            <div className="card-head">No of Times Charged</div>
                            <div className="card-foot">
                                {/* {cycleCount}&ensp;
                                <i
                                    class="fa fa-xs fa-question-circle"
                                    aria-hidden="true"
                                    id="ltMaxPduTemp"
                                ></i> */}
                                No Data
                            </div>
                        </Card>
                    </Col>
                    <Col xs="12" sm="12" md="3">
                        <Card className="hero-cards">
                            <div className="card-head">No of Times Discharged</div>
                            <div className="card-foot">
                                {/* {cycleCount}&ensp;
                                <i
                                    class="fa fa-xs fa-question-circle"
                                    aria-hidden="true"
                                    id="ltMaxPduTemp"
                                ></i> */}
                                No Data
                            </div>
                        </Card>
                    </Col>
                    <Col xs="12" sm="12" md="3">
                        <Card className="hero-cards">
                            <div className="card-head"> Cycle Count</div>
                            <div className="card-foot">
                                {cycleCount}&ensp;
                                <i
                                    class="fa fa-xs fa-question-circle"
                                    aria-hidden="true"
                                    id="ltMaxPduTemp"
                                ></i>
                            </div>
                        </Card>
                    </Col>
                    <Col xs="12" sm="12" md="3">
                        <Card className="hero-cards">
                            <div className="card-head">Total Distance Travelled</div>
                            <div className="card-foot">
                                {Math.round(ltAnalysisData.ltTotalDistanceTravelled*100)/100} km
                            </div>
                        </Card>
                    </Col>
                    <Col xs="12" sm="12" md="3">
                        <Card className="hero-cards">
                            <div className="card-head">Overall Efficiency</div>
                            <div className="card-foot">
                                {ltAnalysisData.ltEfficiencyPercentage} %
                            </div>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md='6'>
                        <Card style={{ padding: "1rem" }} className="hero-cards">
                            <h6>Daily Usage</h6>
                            <Line data={energyInChart.data} options={energyInChart.options} />
                        </Card>
                    </Col>
                    <Col md='6'>
                        <Card style={{ padding: "1rem" }} className="hero-cards">
                            <h6>Cummulative Usage <span style={{ fontSize: '80%', textTransform: 'none' }}>(kW)</span></h6>
                            <Line data={energyOutIncrementChart.data} options={energyOutIncrementChart.options} />
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md='4'>
                        <Card style={{ padding: "1rem" }} className="hero-cards">
                            <h6>Faults </h6>
                            {totalFaultCount ?  <Pie data={lifeTimeFaultChart.data} options={lifeTimeFaultChart.options} />:<img src={Nodatagraph}></img>}
                            <strong>Total: {totalFaultCount}</strong>
                        </Card>
                    </Col>

                    <Col md='4'>
                        <Card style={{ padding: "1rem" }} className="hero-cards">
                            <h6>Over Cell Voltage </h6>
                            {ltAnalysisData.ltOcvFault.total > 0 ? <Doughnut data={ltOCVFaultChart.data} options={ltOCVFaultChart.options} /> : <img src={Nodatagraph}></img>}
                            <strong>Total: {ltAnalysisData.ltOcvFault.total}</strong>
                        </Card>
                    </Col>
                    <Col md='4'>
                        <Card style={{ padding: "1rem" }} className="hero-cards">
                            <h6>Under Cell Voltage </h6>
                            {ltAnalysisData.ltUcvFault.total > 0 ? <Doughnut data={ltUCVFaultChart.data} options={ltUCVFaultChart.options} /> : <img src={Nodatagraph}></img>}
                            <strong>Total: {ltAnalysisData.ltUcvFault.total}</strong>
                        </Card>
                    </Col>
                    <Col md='4'>
                        <Card style={{ padding: "1rem" }} className="hero-cards">
                            <h6>Over Temperature (Charging)</h6>
                            {ltAnalysisData.ltOverCellTempChargingObj.total > 0 ? <Doughnut data={ltOverCellTempChargingObjChart.data} options={ltOverCellTempChargingObjChart.options} /> : <img src={Nodatagraph}></img>}
                            <strong>Total: {ltAnalysisData.ltOverCellTempChargingObj.total}</strong>
                        </Card>
                    </Col>
                    <Col md='4'>
                        <Card style={{ padding: "1rem" }} className="hero-cards">
                            <h6>Over Temperature (Discharging)</h6>
                            {ltAnalysisData.ltOverCellTempDisChargingObj.total > 0 ? <Doughnut data={ltOverCellTempDisChargingObjChart.data} options={ltOverCellTempDisChargingObjChart.options} /> : <img src={Nodatagraph}></img>}
                            <strong>Total: {ltAnalysisData.ltOverCellTempDisChargingObj.total}</strong>
                        </Card>
                    </Col>
                </Row>
                {/* </div>
                <button onClick={generatePdf}>Generate PDF</button> */}
            </div>
        )
    }
else return <div>No Data</div>
}
else return <div>No Data</div>
}
    else return <div>loading...</div>
}
export default LifeTimeAnalysis
