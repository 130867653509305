import React from 'react'
import { Button } from 'reactstrap'

function ClusterFota(props) {
  return (
    <div>
          <br />
          {props.apiFileUploadSuccess ? (
            props.apiFotaFileSize === 122496? 'Firmware File Ready':'Firmware File Size Not Correct' 
          ) : (
            <>
            <strong>Enter Firmware File Name</strong>
            <input type='text' onChange={(e)=>props.setApiFotaFileInputHandler(e.target.value)}></input>
              <Button onClick={props.apiFOTAFile}>Get Update File</Button> 
              <br />
              or
              <br />
              <input
                type="file"
                accept=".bin"
                onChange={props.BinFileInput}
              ></input>
            </>
          )}
          <br />
          <br />
          {props.fotaProcess ? (
            props.fotaProcess
          ) : (
            <Button
              onClick={()=>props.UpdateCode('cluster')}
              disabled={props.startUpdatingDisabled || !props.fileUploaded}
            >
              Start Updating
            </Button>
          )}
          {props.incrementXModem ? (
            <h3>
              {Math.ceil(
                (props.incrementXModem / props.xModem128ByteChunk.length) * 100
              )}
              %
            </h3>
          ) : (
            ""
          )}
          {props.fotaSuccessful ? <div>Update Successful</div> : <></>}
        </div>
  )
}

export default ClusterFota